<template>
  <transition name="slide">
    <div v-if="showPanel" class="side-panel-container">
      <div
        :class="{
          wide: isWideSize,
          full: isFullSize,
          small: isSmallSize,
          multiPanel: isMultiPanel,
        }"
        class="side-panel-main"
      >
        <div class="side-panel-header">
          <h4 class="title">{{ getTitle }}</h4>
          <div class="panel-header-options">
            <button
              v-if="
                showShowLettreAccordButton &&
                secondSidePanel !== 'PREVIEW_LETTRE_ACCORD'
              "
              class="custom-btn open-la-btn"
              @click="showSecondPanelPreviewLA"
            >
              <IconOpenInNew :height="22" />
              Lettre Accord
            </button>
            <img
              alt="close modal"
              src="@/assets/cross.svg"
              @click="close"
              @keyup.enter="close"
            />
          </div>
        </div>
        <div
          id="side-panel-content"
          :class="{
            'has-footer': hasPanelFooter,
          }"
        >
          <FormCreateOperation
            v-if="isAddOperationPanel"
            @hide-side-panel="close"
          />
          <FormCreateDemandes
            v-else-if="isAddDemandesPanel"
            @hide-side-panel="close"
          />
          <FormCreateAvenant
            v-else-if="isAddAvenantPanel"
            @hide-side-panel="close"
          />
          <NotificationList
            v-else-if="isNotificationPanel"
            @hide-side-panel="close"
          />
          <AddBanque v-else-if="isAddBanquePanel" @hide-side-panel="close" />
          <AddMembers v-else-if="isAddMembersPanel" @hide-side-panel="close" />
          <FormUploadOperationDocument
            v-else-if="isAddOperationDocumentsPanel"
            @hide-side-panel="close"
          />
          <FormUploadOperationDocument
            v-else-if="isAddDemandeDocumentsPanel"
            :is-demande="true"
            @hide-side-panel="close"
          />

          <FormUploadSocieteSupportDocument
            v-else-if="isAddSocieteSupportDocumentsPanel"
            @hide-side-panel="close"
          />

          <FormUploadSuiviBudgetPromoteurDocument
            v-else-if="isUploadSuiviBudgetPromoteurDocument"
            @hide-side-panel="close"
          />
          <FormCreateSocieteSupport
            v-else-if="isAddSocieteSupportPanel"
            @hide-side-panel="close"
          />
          <FormCreateAccount
            v-else-if="isAddAccountPanel"
            @hide-side-panel="close"
          />
          <FormCreateLettreAccord
            v-else-if="isAddLettreAccordPanel"
            @hide-side-panel="close"
          />
          <FormCreateSocieteSupportSurete
            v-else-if="isCreateSocieteSupportSuretePanel"
            @hide-side-panel="close"
          />
          <FormCreateOperationSurete
            v-else-if="isCreateOperationSuretePanel"
            @hide-side-panel="close"
          />
          <FormAddCommercialisation
            v-else-if="isAddCommercialisationPanel"
            @hide-side-panel="close"
          ></FormAddCommercialisation>
          <FormCreateUser
            v-else-if="isAddCollaborateurPanel"
            @hide-side-panel="close"
          ></FormCreateUser>
          <FormCreateActeSecondaire
            v-else-if="isAddActeSecondairePanel"
            @hide-side-panel="close"
          >
          </FormCreateActeSecondaire>
          <FormAddDemandeDocumentValidation
            v-else-if="isAddDemandeDocumentValidationPanel"
            @hide-side-panel="close"
          />
          <FormCreateMainLeveeStepOne
            v-else-if="isAddMainLeveePanel"
            @hide-side-panel="close"
          />
          <FormAddMainLeveeDocument
            v-else-if="isAddMainLeveeDocumentPanel"
            @hide-side-panel="close"
          />
          <FormCheckMainLevee
            v-else-if="isCheckMainLeveePanel"
            @hide-side-panel="close"
          />
          <FormFixMultipleTrancheAmount
            v-else-if="isFixMultipleTrancheAmount"
            @hide-side-panel="close"
          />
          <FormAddMainLeveeDemandeComplementaire
            v-else-if="isAddMainLeveeDemandeComplementairePanel"
            @hide-side-panel="close"
          />
          <FormManageCMP
            v-else-if="isManageConditionCMPPanel"
            :is-preview-lettre-accord-panel-open="
              isSecondSidePanelPreviewLettreAccord
            "
            :waiting-for-close-confirmation="isWaitingForCloseConfirmation"
            data-test="form-manage-cmp"
            @cancelled="cancelCloseModal"
            @validated="confirmCloseModal"
            @hide-side-panel="hideSidePanel"
          />
          <FormEditDocumentCmp
            v-else-if="isEditDocumentCmpPanel"
            @hide-side-panel="close"
          />
          <FormAddMemberTiers
            v-else-if="isAddMemberTiersPanel"
            @hide-side-panel="close"
          />
          <FormCreateAccordCommercial
            v-else-if="isCreateCspPanel"
            @hide-side-panel="close"
          />
          <FormCreatePoolFormalise
            v-else-if="isCreatePoolFormalise"
            @hide-side-panel="close"
          />
          <FormEditTiers
            v-else-if="isEditTiersPanel"
            @hide-side-panel="close"
          />
          <FormEditSurete
            v-else-if="isEditSuretePanel"
            @hide-side-panel="close"
          />
          <FormAddBanquePartenairePool
            v-else-if="isAddBanquePartenairePool"
            @hide-side-panel="close"
          />
          <FormManagePoolUsers
            v-else-if="isManagePoolUsersPanel"
            @hide-side-panel="close"
          />
          <FormAddEtapeSuiviTravaux
            v-else-if="isAddEtapeSuiviTravaux"
            @hide-side-panel="close"
          />
          <FormBulkCreatePoolAccordCommercial
            v-else-if="isCreatePoolGroupedContract"
            @hide-side-panel="close"
          />
          <FormSureteAssociateOperation
            v-else-if="isAssociateSureteToOperation"
            @hide-side-panel="close"
          />
          <FormOperationAssociateSurete
            v-else-if="isAssociateOperationSurete"
            @hide-side-panel="close"
          />
          <ImportSuiviTravaux
            v-else-if="isImportSuiviTravaux"
            @hide-side-panel="close"
          />
          <FormUpdateLettreAccord
            v-else-if="isUpdateLettreAccord"
            @hide-side-panel="close"
          />
          <FormdAddConditionContainer
            v-else-if="isOffreCommercialeValidationSousCondition"
            @hide-side-panel="close"
          />
          <FormSuiviBudgetConfiguration
            v-else-if="isSuiviBudgetConfiguration"
            @hide-side-panel="close"
          />
        </div>
      </div>
      <PreviewLettreAccordPanel
        v-if="isSecondSidePanelPreviewLettreAccord"
        class="side-panel-secondary"
        data-test="preview-lettre-accord-panel"
        @hide-side-panel="closeSecondSidePanel"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { PanelType } from "@/utils/model/panelType";
import { SidePanelSize } from "@/domain/enum/sidePanelSize";
import config from "@/config";
import { getTestMatrix } from "@/utils/suiviCommercialisationUtils";

import { computed, defineAsyncComponent, defineComponent, ref } from "vue";
import { getSetupContext } from "@/utils/vueUtils";
import IconOpenInNew from "../icons/IconOpenInNew.vue";
import { SecondPanelType } from "@/utils/model/secondPanelType";

const FormBulkCreatePoolAccordCommercial = defineAsyncComponent(
  () => import("@/components/form/FormBulkCreatePoolAccordCommercial.vue")
);

const FormCreateOperation = defineAsyncComponent(
  () => import("@/components/form/FormCreateOperation.vue")
);

const FormUploadSocieteSupportDocument = defineAsyncComponent(
  () => import("@/components/form/FormUploadSocieteSupportDocument.vue")
);

const FormUploadOperationDocument = defineAsyncComponent(
  () => import("@/components/form/FormUploadOperationDocument.vue")
);

const NotificationList = defineAsyncComponent(
  () => import("@/components/notification/NotificationList.vue")
);

const AddBanque = defineAsyncComponent(
  () => import("@/components/group/AddBanque.vue")
);

const AddMembers = defineAsyncComponent(
  () => import("@/components/group/AddMembers.vue")
);

const FormCreateDemandes = defineAsyncComponent(
  () => import("@/components/form/FormCreateDemandes.vue")
);

const FormCreateAvenant = defineAsyncComponent(
  () => import("@/components/form/FormCreateAvenant.vue")
);

const FormCreateSocieteSupport = defineAsyncComponent(
  () => import("@/components/form/FormCreateSocieteSupport.vue")
);

const FormCreateAccount = defineAsyncComponent(
  () => import("@/components/form/FormCreateAccount.vue")
);

const FormCreateLettreAccord = defineAsyncComponent(
  () => import("@/components/form/FormCreateLettreAccord.vue")
);

const FormCreateSocieteSupportSurete = defineAsyncComponent(
  () => import("@/components/form/FormCreateSocieteSupportSurete.vue")
);

const FormCreateOperationSurete = defineAsyncComponent(
  () => import("@/components/form/FormCreateOperationSurete.vue")
);

const FormAddCommercialisation = defineAsyncComponent(
  () => import("@/components/form/FormAddCommercialisation.vue")
);

const FormCreateUser = defineAsyncComponent(
  () => import("@/components/form/FormCreateUser.vue")
);
const FormCreateActeSecondaire = defineAsyncComponent(
  () => import("@/components/form/FormCreateActeSecondaire.vue")
);
const FormAddDemandeDocumentValidation = defineAsyncComponent(
  () => import("@/components/form/FormAddDemandeDocumentValidation.vue")
);
const FormCreateMainLeveeStepOne = defineAsyncComponent(
  () => import("@/components/form/FormCreateMainLevee.vue")
);
const FormCheckMainLevee = defineAsyncComponent(
  () => import("@/components/form/FormCheckMainLevee.vue")
);
const FormFixMultipleTrancheAmount = defineAsyncComponent(
  () => import("@/components/form/FormFixMultipleTrancheAmount.vue")
);

const FormAddMainLeveeDocument = defineAsyncComponent(
  () => import("@/components/form/FormUploadMainLeveeDocument.vue")
);

const FormAddMainLeveeDemandeComplementaire = defineAsyncComponent(
  () => import("@/components/form/FormAddMainLeveeDemandeComplementaire.vue")
);

const FormManageCMP = defineAsyncComponent(
  () => import("@/components/form/conditionMiseEnPlace/FormManageCMP.vue")
);

const PreviewLettreAccordPanel = defineAsyncComponent(
  () =>
    import("@/components/shared/secondSidePanel/PreviewLettreAccordPanel.vue")
);

const FormEditDocumentCmp = defineAsyncComponent(
  () => import("@/components/form/FormEditDocumentCmp.vue")
);

const FormAddMemberTiers = defineAsyncComponent(
  () => import("@/components/form/tiers/FormAddMemberTiers.vue")
);

const FormCreateAccordCommercial = defineAsyncComponent(
  () => import("@/components/form/FormCreateAccordCommercial.vue")
);

const FormCreatePoolFormalise = defineAsyncComponent(
  () => import("@/components/form/FormCreatePoolFormalise.vue")
);
const FormEditTiers = defineAsyncComponent(
  () => import("@/components/form/tiers/FormEditTiers.vue")
);

const FormEditSurete = defineAsyncComponent(
  () => import("@/components/form/FormEditSurete.vue")
);
const FormAddBanquePartenairePool = defineAsyncComponent(
  () => import("@/components/form/FormAddBanquePartenairePoolFormalise.vue")
);

const FormManagePoolUsers = defineAsyncComponent(
  () => import("@/components/form/FormManagePoolUsers.vue")
);

const FormAddEtapeSuiviTravaux = defineAsyncComponent(
  () => import("@/components/form/FormAddEtapeSuiviTravaux.vue")
);

const FormSureteAssociateOperation = defineAsyncComponent(
  () => import("@/components/form/FormSureteAssociateOperation.vue")
);

const FormOperationAssociateSurete = defineAsyncComponent(
  () => import("@/components/form/FormOperationAssociateSurete.vue")
);

const ImportSuiviTravaux = defineAsyncComponent(
  () => import("@/components/suiviTravauxMassif/SuiviTravauxImportPanel.vue")
);

const FormUpdateLettreAccord = defineAsyncComponent(
  () => import("@/components/form/FormUpdateLettreAccord.vue")
);
const FormUploadSuiviBudgetPromoteurDocument = defineAsyncComponent(
  () => import("@/components/form/FormUploadSuiviBudgetPromoteurDocument.vue")
);

const FormdAddConditionContainer = defineAsyncComponent(
  () => import("@/components/form/FormAccordCommercialCondition.vue")
);

const FormSuiviBudgetConfiguration = defineAsyncComponent(
  () => import("@/components/suiviBudget/SuiviBudgetConfigurationPanel.vue")
);

enum TopButton {
  Close = "Close",
  LettreAccord = "LettreAccord",
}

export default defineComponent({
  name: "SidePanel",
  components: {
    FormCreateActeSecondaire,
    FormUploadSocieteSupportDocument,
    FormUploadSuiviBudgetPromoteurDocument,
    FormUploadOperationDocument,
    NotificationList,
    AddBanque,
    AddMembers,
    FormCreateDemandes,
    FormCreateAvenant,
    FormCreateOperation,
    FormCreateSocieteSupport,
    FormCreateAccount,
    FormCreateLettreAccord,
    FormCreateSocieteSupportSurete,
    FormCreateOperationSurete,
    FormAddCommercialisation,
    FormCreateUser,
    FormAddDemandeDocumentValidation,
    FormCreateMainLeveeStepOne,
    FormCheckMainLevee,
    FormAddMainLeveeDocument,
    FormFixMultipleTrancheAmount,
    FormAddMainLeveeDemandeComplementaire,
    FormManageCMP,
    FormAddMemberTiers,
    FormEditDocumentCmp,
    FormCreateAccordCommercial,
    FormEditTiers,
    FormCreatePoolFormalise,
    FormEditSurete,
    PreviewLettreAccordPanel,
    FormAddBanquePartenairePool,
    FormManagePoolUsers,
    IconOpenInNew,
    FormAddEtapeSuiviTravaux,
    FormBulkCreatePoolAccordCommercial,
    FormSureteAssociateOperation,
    FormOperationAssociateSurete,
    ImportSuiviTravaux,
    FormUpdateLettreAccord,
    FormdAddConditionContainer,
    FormSuiviBudgetConfiguration,
  },
  setup() {
    const { panelStore, suiviCommercialisationStore } = getSetupContext();

    const secondSidePanel = ref<SecondPanelType | undefined>(undefined);

    if (config.DEV_COM) {
      panelStore.SetPanelType(PanelType.ADD_COMMERCIALISATION);
      panelStore.SetPanelSize(SidePanelSize.FULL);
      const matrix = getTestMatrix();
      suiviCommercialisationStore.InitializeCommercialisationImportMatrix(
        matrix
      );
    }

    const isWaitingForCloseConfirmation = ref<boolean>(false);
    const showPanel = computed<boolean>(() => {
      return panelStore.showPanel;
    });

    const panelType = computed<undefined | PanelType>(() => {
      return panelStore.getPanelType;
    });

    const getSidePanelSize = computed<string>(() => {
      return panelStore.getSidePanelSize;
    });

    const isFullSize = computed<boolean>(() => {
      return getSidePanelSize.value === SidePanelSize.FULL;
    });
    const isSmallSize = computed<boolean>(() => {
      return getSidePanelSize.value === SidePanelSize.SMALL;
    });

    const isWideSize = computed<boolean>(() => {
      return getSidePanelSize.value === SidePanelSize.WIDE;
    });

    const isMultiPanel = computed<boolean>(() => {
      return isSecondSidePanelPreviewLettreAccord.value;
    });

    const isAddOperationPanel = computed<boolean>(() => {
      return panelType.value === PanelType.OPERATION;
    });

    const isAddCollaborateurPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_COLLABORATEUR;
    });

    const isAddDemandesPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_DEMANDES;
    });

    const isAddAvenantPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_AVENANT;
    });

    const isAddMainLeveePanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_MAINLEVEE;
    });

    const isAddMainLeveeDocumentPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_MAINLEVEE_DOCUMENT;
    });

    const isCheckMainLeveePanel = computed<boolean>(() => {
      return panelType.value === PanelType.CHECK_MAINLEVEE;
    });

    const isNotificationPanel = computed<boolean>(() => {
      return panelType.value === PanelType.NOTIFICATION;
    });

    const isAddBanquePanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_BANQUE;
    });

    const isAddMembersPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_MEMBERS;
    });

    const isAddOperationDocumentsPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_DOCUMENTS_OPERATION;
    });

    const isAddSocieteSupportDocumentsPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_DOCUMENTS_SOCIETE_SUPPORT;
    });

    const isAddDemandeDocumentsPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_DOCUMENTS_DEMANDE;
    });

    const isAddSocieteSupportPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_SOCIETE_SUPPORT;
    });

    const isAddAccountPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_ACCOUNT;
    });

    const isAddLettreAccordPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_LETTRE_ACCORD;
    });

    const isCreateSocieteSupportSuretePanel = computed<boolean>(() => {
      return panelType.value === PanelType.CREATE_SOCIETE_SUPPORT_SURETE;
    });

    const isCreateOperationSuretePanel = computed((): boolean => {
      return panelType.value === PanelType.CREATE_OPERATION_SURETE;
    });

    const isAddCommercialisationPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_COMMERCIALISATION;
    });

    const isAddActeSecondairePanel = computed<boolean>(() => {
      return panelType.value === PanelType.MANAGE_ACTE_SECONDAIRE;
    });
    const isAddDemandeDocumentValidationPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_DEMANDE_DOCUMENT_VALIDATION;
    });

    const isFixMultipleTrancheAmount = computed<boolean>(() => {
      return panelType.value === PanelType.FIX_MULTIPLE_TRANCHE_AMOUNT;
    });

    const isAddMainLeveeDemandeComplementairePanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_MAINLEVEE_DEMANDE_COMPLEMENTAIRE;
    });
    const isManageConditionCMPPanel = computed<boolean>(() => {
      return panelType.value === PanelType.MANAGE_CMP;
    });

    const isEditDocumentCmpPanel = computed<boolean>(() => {
      return panelType.value === PanelType.EDIT_DOCUMENT_CMP;
    });

    const isAddMemberTiersPanel = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_TIERS;
    });

    const isCreateCspPanel = computed<boolean>(() => {
      return panelType.value === PanelType.CREATE_CSP;
    });

    const isCreatePoolFormalise = computed<boolean>(() => {
      return panelType.value === PanelType.CREATE_POOL_FORMALISE;
    });
    const isEditTiersPanel = computed<boolean>(() => {
      return panelType.value === PanelType.EDIT_TIERS;
    });

    const isEditSuretePanel = computed<boolean>(() => {
      return panelType.value === PanelType.EDIT_SURETE;
    });
    const isAddBanquePartenairePool = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_BANQUE_PARTENAIRE_POOL_FORMALISE;
    });

    const isManagePoolUsersPanel = computed<boolean>(() => {
      return panelType.value === PanelType.MANAGE_POOL_GROUP_USERS;
    });

    const isCreatePoolGroupedContract = computed<boolean>(() => {
      return panelType.value === PanelType.CREATE_POOL_GROUPED_CONTRACT;
    });

    const isAssociateSureteToOperation = computed<boolean>(() => {
      return panelType.value === PanelType.ASSOCIATE_SURETE_TO_OPERATION;
    });

    const isAssociateOperationSurete = computed<boolean>(() => {
      return panelType.value === PanelType.ASSOCIATE_OPERATION_TO_SURETE;
    });

    const isAddEtapeSuiviTravaux = computed<boolean>(() => {
      return panelType.value === PanelType.ADD_ETAPE_SUIVI_TRAVAUX;
    });

    const isImportSuiviTravaux = computed<boolean>(() => {
      return panelType.value === PanelType.IMPORT_SUIVI_TRAVAUX;
    });

    const isUpdateLettreAccord = computed<boolean>(() => {
      return panelType.value === PanelType.UPDATE_LETTRE_ACCORD;
    });
    const isUploadSuiviBudgetPromoteurDocument = computed<boolean>(() => {
      return panelType.value === PanelType.IMPORT_SUIVI_BUDGET;
    });
    const isSuiviBudgetConfiguration = computed<boolean>(() => {
      return panelType.value === PanelType.CONFIGURE_SUIVI_BUDGET;
    });

    const isOffreCommercialeValidationSousCondition = computed<boolean>(() => {
      return (
        panelType.value ===
        PanelType.OFFRE_COMMERCIALE_VALIDATION_SOUS_CONDITION
      );
    });

    const getTitle = computed<string>(() => {
      switch (panelType.value) {
        case PanelType.NOTIFICATION:
          return "Notifications";
        case PanelType.ADD_BANQUE:
          return "Choisir une banque";
        case PanelType.ADD_DEMANDES:
          return "Créer une demande";
        case PanelType.ADD_AVENANT:
          return "Avenant";
        case PanelType.ADD_MEMBERS:
          return "Inviter des membres";
        case PanelType.OPERATION:
          return "Créer une opération";
        case PanelType.ADD_SOCIETE_SUPPORT:
          return "Ajouter une société support";
        case PanelType.ADD_ACCOUNT:
          return "Ajouter un compte";
        case PanelType.CREATE_SOCIETE_SUPPORT_SURETE:
        case PanelType.CREATE_OPERATION_SURETE:
          return "Nouvelle sûreté";
        case PanelType.ADD_DOCUMENTS_OPERATION:
        case PanelType.ADD_DOCUMENTS_DEMANDE:
        case PanelType.ADD_DOCUMENTS_SOCIETE_SUPPORT:
          return "Ajouter des documents";
        case PanelType.ADD_LETTRE_ACCORD:
          return "Créer une lettre d'accord";
        case PanelType.ADD_COMMERCIALISATION:
          return "Mettre à jour la commercialisation";
        case PanelType.ADD_COLLABORATEUR:
          return "Inviter un collaborateur";
        case PanelType.MANAGE_ACTE_SECONDAIRE:
          return "Demande et actes secondaires";
        case PanelType.ADD_DEMANDE_DOCUMENT_VALIDATION:
          return "Ajout de documents - Non partagé";
        case PanelType.ADD_MAINLEVEE:
        case PanelType.CHECK_MAINLEVEE:
        case PanelType.ADD_MAINLEVEE_DEMANDE_COMPLEMENTAIRE:
          return "Demande de mainlevée";
        case PanelType.ADD_TIERS:
          return "Ajouter des tiers";
        case PanelType.CREATE_CSP:
          return "Ajouter un contrat";
        case PanelType.EDIT_SURETE:
          return "Éditer une sûrete";
        case PanelType.MANAGE_CMP:
          return panelStore.getCustomTitle;
        case PanelType.EDIT_TIERS:
          return "Edition de l'invité";
        case PanelType.FIX_MULTIPLE_TRANCHE_AMOUNT: {
          const ligneEditTrancheList =
            suiviCommercialisationStore.getFixTrancheAmount;
          if (ligneEditTrancheList.length > 0) {
            return `Tranche ${ligneEditTrancheList[0].tranche.tranche} - association de GFA`;
          }

          console.error("ligneEditTrancheList is empty");
          return `Tranche 01 - association de GFA`;
        }
        case PanelType.ADD_MAINLEVEE_DOCUMENT:
          return "Ajout de document sur une mainlevée";
        case PanelType.EDIT_DOCUMENT_CMP:
          return "Modification d'un document";
        case PanelType.CREATE_POOL_FORMALISE:
          return "Création d’un pool pré-paramétré";
        case PanelType.ADD_BANQUE_PARTENAIRE_POOL_FORMALISE:
        case PanelType.MANAGE_POOL_GROUP_USERS:
          return "Ajouter une banque partenaire";
        case PanelType.ADD_ETAPE_SUIVI_TRAVAUX:
          return "Ajouter une étape de suivi des travaux";
        case PanelType.CREATE_POOL_GROUPED_CONTRACT:
          return "Ajout et partage de documents";
        case PanelType.ASSOCIATE_SURETE_TO_OPERATION:
          return "Associer une sûreté à une opération";
        case PanelType.ASSOCIATE_OPERATION_TO_SURETE:
          return "Associer une sûreté";
        case PanelType.IMPORT_SUIVI_TRAVAUX:
          return "Mettre à jour le suivi des travaux";
        case PanelType.UPDATE_LETTRE_ACCORD:
          return "Éditer la lettre d'accord";
        case PanelType.IMPORT_SUIVI_BUDGET:
          return "Initialisation du budget";
        case PanelType.CONFIGURE_SUIVI_BUDGET:
          return "Finaliser l’initialisation du suivi du budget";
        case PanelType.OFFRE_COMMERCIALE_VALIDATION_SOUS_CONDITION:
          return "Ajouter des conditions";
        default:
          return "";
      }
    });

    const hasPanelFooter = computed<boolean>(() => {
      if (!panelType.value) {
        return false;
      }
      const panelsWithFooter: PanelType[] = [
        PanelType.OPERATION,
        PanelType.ADD_DEMANDES,
        PanelType.ADD_AVENANT,
        PanelType.ADD_BANQUE,
        PanelType.CREATE_SOCIETE_SUPPORT_SURETE,
        PanelType.CREATE_OPERATION_SURETE,
        PanelType.ADD_LETTRE_ACCORD,
        PanelType.ADD_SOCIETE_SUPPORT,
        PanelType.ADD_ACCOUNT,
        PanelType.ADD_MEMBERS,
        PanelType.ADD_DOCUMENTS_OPERATION,
        PanelType.ADD_DOCUMENTS_DEMANDE,
        PanelType.ADD_DOCUMENTS_SOCIETE_SUPPORT,
        PanelType.ADD_COLLABORATEUR,
        PanelType.MANAGE_ACTE_SECONDAIRE,
        PanelType.ADD_DEMANDE_DOCUMENT_VALIDATION,
        PanelType.EDIT_DOCUMENT_CMP,
        PanelType.MANAGE_CMP,
        PanelType.CREATE_CSP,
        PanelType.CREATE_POOL_FORMALISE,
        PanelType.EDIT_SURETE,
        PanelType.EDIT_TIERS,
        PanelType.ADD_BANQUE_PARTENAIRE_POOL_FORMALISE,
        PanelType.MANAGE_POOL_GROUP_USERS,
        PanelType.ADD_ETAPE_SUIVI_TRAVAUX,
        PanelType.CREATE_POOL_GROUPED_CONTRACT,
        PanelType.ASSOCIATE_SURETE_TO_OPERATION,
        PanelType.ASSOCIATE_OPERATION_TO_SURETE,
        PanelType.UPDATE_LETTRE_ACCORD,
        PanelType.IMPORT_SUIVI_BUDGET,
        // PanelType.CONFIGURE_SUIVI_BUDGET,
        PanelType.OFFRE_COMMERCIALE_VALIDATION_SOUS_CONDITION,
      ];
      return panelsWithFooter.includes(panelType.value);
    });

    const topButton = computed((): TopButton => {
      switch (panelType.value) {
        case PanelType.MANAGE_CMP:
          return TopButton.LettreAccord;
        default:
          return TopButton.Close;
      }
    });

    const shouldShowCloseButton = computed((): boolean => {
      return topButton.value === TopButton.Close;
    });

    const showShowLettreAccordButton = computed((): boolean => {
      return topButton.value === TopButton.LettreAccord;
    });

    const isSecondSidePanelPreviewLettreAccord = computed((): boolean => {
      return secondSidePanel.value === SecondPanelType.PREVIEW_LETTRE_ACCORD;
    });

    function closeSecondSidePanel() {
      secondSidePanel.value = undefined;
    }

    function showSecondPanelPreviewLA() {
      secondSidePanel.value = SecondPanelType.PREVIEW_LETTRE_ACCORD;
    }

    function closeSidePanel(): void {
      closeSecondSidePanel();
      panelStore.CloseSidePanel();
    }

    function closeManageConditionCMPPanel(
      forceCloseSidepanel: boolean = false
    ): void {
      if (forceCloseSidepanel) {
        closeSidePanel();
      } else {
        isWaitingForCloseConfirmation.value = true;
      }
    }

    function close(_e?: Event | null, forceCloseSidepanel?: boolean): void {
      if (isManageConditionCMPPanel.value) {
        closeManageConditionCMPPanel(forceCloseSidepanel);
      } else {
        closeSidePanel();
      }
    }

    function confirmCloseModal(): void {
      isWaitingForCloseConfirmation.value = false;
      panelStore.CloseSidePanel();
      closeSecondSidePanel();
    }

    function cancelCloseModal(): void {
      isWaitingForCloseConfirmation.value = false;
    }

    function hideSidePanel(forceCloseSidepanel?: boolean) {
      close(null, forceCloseSidepanel);
    }

    return {
      showPanel,
      panelType,
      getSidePanelSize,
      isFullSize,
      isWideSize,
      isSmallSize,
      isMultiPanel,
      isAddOperationPanel,
      isAddCollaborateurPanel,
      isAddDemandesPanel,
      isAddAvenantPanel,
      isAddMainLeveePanel,
      isAddMainLeveeDocumentPanel,
      isCheckMainLeveePanel,
      isNotificationPanel,
      isAddBanquePanel,
      isAddMembersPanel,
      isAddOperationDocumentsPanel,
      isAddSocieteSupportDocumentsPanel,
      isAddDemandeDocumentsPanel,
      isAddSocieteSupportPanel,
      isAddAccountPanel,
      isAddLettreAccordPanel,
      isCreateSocieteSupportSuretePanel,
      isCreateOperationSuretePanel,
      isAddCommercialisationPanel,
      isAddActeSecondairePanel,
      isAddDemandeDocumentValidationPanel,
      isEditDocumentCmpPanel,
      isFixMultipleTrancheAmount,
      isAddMemberTiersPanel,
      isCreateCspPanel,
      isCreatePoolFormalise,
      isEditSuretePanel,
      isCreatePoolGroupedContract,
      isAssociateOperationSurete,
      isUpdateLettreAccord,
      hasPanelFooter,
      isManageConditionCMPPanel,
      isAddMainLeveeDemandeComplementairePanel,
      isSecondSidePanelPreviewLettreAccord,
      getTitle,
      isEditTiersPanel,
      shouldShowCloseButton,
      showShowLettreAccordButton,
      isWaitingForCloseConfirmation,
      secondSidePanel,
      isAddBanquePartenairePool,
      isManagePoolUsersPanel,
      isAddEtapeSuiviTravaux,
      isAssociateSureteToOperation,
      isImportSuiviTravaux,
      isUploadSuiviBudgetPromoteurDocument,
      isSuiviBudgetConfiguration,
      isOffreCommercialeValidationSousCondition,
      showSecondPanelPreviewLA,
      confirmCloseModal,
      closeSecondSidePanel,
      close,
      cancelCloseModal,
      hideSidePanel,
    };
  },
});
</script>

<style v-if="showPanel">
html {
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
@import "@/sass/media.scss";

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.side-panel-container {
  position: fixed;
  background-color: $white;
  top: 0;
  right: 0;

  z-index: 500;
  overflow-y: hidden;
  box-shadow: $default-shadow;

  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-end;
  height: 100%;

  .side-panel-secondary {
    border-left: 1px solid $grey;
  }

  > .side-panel-main,
  > :deep(.side-panel-secondary) {
    display: flex;
    flex-direction: column;

    @include side-panel-width;

    &.wide {
      @include side-panel-wide-width;
    }

    &.full {
      width: 100%;
    }

    &.small {
      width: 770px;
    }

    &.multiPanel {
      max-width: 770px;
      min-width: 400px;
      width: calc(100vw - 900px);
    }

    .side-panel-header {
      width: 100%;
      flex: 0 0 88px;
      display: flex;
      background: $primary-main;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      position: relative;
      z-index: 2;
      @include lg {
        h3 {
          font-size: 34px;
        }
      }

      .title {
        color: $white;
        margin: 0 32px;
        font-size: 22px;
        line-height: 40px;
      }

      img {
        cursor: pointer;
        min-height: 40px;
        min-width: 40px;
        margin: 0 32px;
      }

      .panel-header-options {
        display: flex;
      }
    }
  }

  :deep(.side-panel-secondary) {
    min-width: 900px;

    @media (max-width: 1323px) {
      min-width: 572px;
    }
  }
}

.open-la-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  gap: 10px;

  background: transparent;
  border: none;
  font-size: 20px;
  line-height: 40px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
</style>
