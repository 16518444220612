export enum PanelType {
  NOTIFICATION = "NOTIFICATION",
  ADD_BANQUE = "ADD_BANQUE",
  ADD_DEMANDES = "ADD_DEMANDES",
  ADD_AVENANT = "ADD_AVENANT",
  ADD_MAINLEVEE = "ADD_MAINLEVEE",
  ADD_MAINLEVEE_DOCUMENT = "ADD_MAINLEVEE_DOCUMENT",
  CHECK_MAINLEVEE = "CHECK_MAINLEVEE",
  ADD_MEMBERS = "ADD_MEMBERS",
  ADD_TIERS = "ADD_TIERS",
  OPERATION = "OPERATION",
  ADD_SOCIETE_SUPPORT = "ADD_SOCIETE_SUPPORT",
  ADD_ACCOUNT = "ADD_ACCOUNT",
  ADD_DOCUMENTS_OPERATION = "ADD_DOCUMENTS_OPERATION",
  ADD_DOCUMENTS_SOCIETE_SUPPORT = "ADD_DOCUMENTS_SOCIETE_SUPPORT",
  ADD_DOCUMENTS_DEMANDE = "ADD_DOCUMENTS_DEMANDE",
  ADD_LETTRE_ACCORD = "ADD_LETTRE_ACCORD",
  CREATE_SOCIETE_SUPPORT_SURETE = "CREATE_SOCIETE_SUPPORT_SURETE",
  ADD_COMMERCIALISATION = "ADD_COMMERCIALISATION",
  ADD_COLLABORATEUR = "ADD_COLLABORATEUR",
  MANAGE_ACTE_SECONDAIRE = "MANAGE_ACTE_SECONDAIRE",
  ADD_DEMANDE_DOCUMENT_VALIDATION = "ADD_DEMANDE_DOCUMENT_VALIDATION",
  FIX_MULTIPLE_TRANCHE_AMOUNT = "FIX_MULTIPLE_TRANCHE_AMOUNT",
  ADD_MAINLEVEE_DEMANDE_COMPLEMENTAIRE = "ADD_MAINLEVEE_DEMANDE_COMPLEMENTAIRE",
  MANAGE_CMP = "MANAGE_CMP",
  EDIT_DOCUMENT_CMP = "EDIT_DOCUMENT_CMP",
  CREATE_CSP = "CREATE_CSP",
  CREATE_POOL_FORMALISE = "CREATE_POOL_FORMALISE",
  EDIT_TIERS = "EDIT_TIERS",
  EDIT_SURETE = "EDIT_SURETE",
  ADD_BANQUE_PARTENAIRE_POOL_FORMALISE = "ADD_BANQUE_PARTENAIRE_POOL_FORMALISE",
  MANAGE_POOL_GROUP_USERS = "MANAGE_POOL_GROUP_USERS",
  ADD_ETAPE_SUIVI_TRAVAUX = "ADD_ETAPE_SUIVI_TRAVAUX",
  CREATE_POOL_GROUPED_CONTRACT = "CREATE_POOL_GROUPED_CONTRACT",
  ASSOCIATE_SURETE_TO_OPERATION = "ASSOCIATE_SURETE_TO_OPERATION",
  ASSOCIATE_OPERATION_TO_SURETE = "ASSOCIATE_OPERATION_TO_SURETE",
  CREATE_OPERATION_SURETE = "CREATE_OPERATION_SURETE",
  IMPORT_SUIVI_TRAVAUX = "IMPORT_SUIVI_TRAVAUX",
  UPDATE_LETTRE_ACCORD = "LETTRE_ACCORD_UPDATE",
  IMPORT_SUIVI_BUDGET = "IMPORT_SUIVI_BUDGET",
  CONFIGURE_SUIVI_BUDGET = "CONFIGURE_SUIVI_BUDGET",
  OFFRE_COMMERCIALE_VALIDATION_SOUS_CONDITION = "OFFRE_COMMERCIALE_VALIDATION_SOUS_CONDITION",
}
