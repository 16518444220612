import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { CreationDocument } from "@/domain/creationDocument";
import { DemandeUserRoom } from "@domain/dto/demandeUserRoom";
import {
  EditionOperationDemandeDocument,
  EditionOperationESGFormDocument,
  EditionOperationLettreAccordDocument,
} from "@domain/dto/collabora/editionOperationDocument";
import { OperationHeavy } from "@/domain/operationHeavy";
import { DocumentEditionStatus } from "@/domain/enum/documentEditionStatus";
import { DocumentStatus } from "@domain/enum/documentStatus";
import { GedDocument } from "@/domain/gedDocument";
import { OperationDocument } from "@domain/dto/operationDocument";
import { SocieteSupportDocument } from "@domain/dto/societeSupportDocument";
import {
  createOperationDocumentUploadPayloadMetadata,
  documentMetadataListToMap,
} from "@/utils/documentUtils";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import { DocumentUploadError } from "@/domain/error/documentUploadError";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { OperationZipFileRequest } from "@domain/dto/operationZipFileRequest";
import { OperationZipFileResponse } from "@domain/dto/operationZipFileResponse";
import { LettreAccordUserRoom } from "@domain/dto/lettreAccordUserRoom";
import { EditionSocieteSupportSureteDocument } from "@domain/dto/collabora/editionSocieteSupportDocument";
import { SureteUserRoom } from "@domain/dto/sureteUserRoom";
import { DocumentUpdateRequest } from "@domain/dto/documentUpdateRequest";
import { ImportDocumentListRequest } from "@domain/dto/ImportDocumentListRequest";

export default {
  operationUploadDocuments(
    idOperation: string,
    documentsToPost: CreationDocument[],
    gedBanqueId: string | undefined,
    callbackProgress: (progress: number) => unknown
  ): Promise<ApiResponse<PendingDocument[]>> {
    const formData: FormData = new FormData();

    formData.append(
      "metadata",
      createOperationDocumentUploadPayloadMetadata(documentsToPost, gedBanqueId)
    );

    documentsToPost.forEach((fileData: CreationDocument) => {
      formData.append("files", fileData.file);
    });

    return apiRequestThrottle.post(
      `/operations/${idOperation}/documents/${documentsToPost.length}`,
      formData,
      {
        validateStatus: function (status) {
          return status === 201;
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;

          if (totalLength && callbackProgress) {
            callbackProgress(
              Math.round((progressEvent.loaded * 100) / totalLength)
            );
          }
        },
      }
    );
  },

  demandeUploadDocuments(
    idOperation: string,
    idDemande: string,
    documentsToPost: CreationDocument[],
    documentStatus: DocumentStatus
  ): Promise<
    | (ApiResponse<OperationDocument[]> & { status: 201 })
    | (ApiResponse<DocumentUploadError> & { status: 400 })
  > {
    const formData: FormData = new FormData();

    const documentsToPostAsMap = documentMetadataListToMap(documentsToPost);

    formData.append("metadatas", JSON.stringify(documentsToPostAsMap));
    documentsToPost.forEach((fileData: CreationDocument) => {
      formData.append("files", fileData.file);
    });

    return apiRequestThrottle.post(
      `/operations/${idOperation}/demandes/${idDemande}/documents?status=${documentStatus}`,
      formData,
      {
        validateStatus: function (status) {
          return (
            status === 201 || status === 502 || status === 422 || status === 400
          );
        },
      }
    );
  },

  uploadDemandeProject(
    idOperation: string,
    idDemande: string,
    document: File
  ): Promise<ApiResponse<PendingDocument[]>> {
    const formData: FormData = new FormData();
    formData.append("demandeProject", document);

    return apiRequestThrottle.post(
      `/operations/${idOperation}/demandes/${idDemande}/project`,
      formData,
      {
        validateStatus: function (status) {
          return (
            status === 201 || status === 409 || status === 502 || status === 422
          );
        },
      }
    );
  },

  operationFetchAllDocuments(
    idOperation: string
  ): Promise<ApiResponse<OperationDocument[]>> {
    return apiRequest.get(`/operations/${idOperation}/documents`);
  },

  fetchDemandeActe(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/documents?document_status=ACTE`
    );
  },
  fetchDemandeAchevementMainLevee(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<OperationDocument[]>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/documents?document_status=ACHEVEMENT_MAINLEVEE`
    );
  },
  operationEditDocument(
    idOperation: string,
    editDocument: DocumentUpdateRequest
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/documents/${editDocument.id}`,
      editDocument
    );
  },

  operationDeleteDocument(
    idOperation: string,
    idDocument: string
  ): Promise<ApiResponse<void>> {
    return apiRequest.delete(
      `/operations/${idOperation}/documents/${idDocument}`
    );
  },

  importDocumentListToOtherGed(
    idOperation: string,
    documentListToImport: OperationDocument[],
    idBanque: string | null
  ): Promise<ApiResponse<OperationDocument>> {
    const payload: ImportDocumentListRequest = {
      idOperationDocumentList: documentListToImport.map(
        (document) => document.id
      ),
      idBanque: idBanque,
    };

    return apiRequestThrottle.patch(
      `/operations/${idOperation}/import-document-list`,
      payload
    );
  },

  downloadDocument(
    idOperation: string,
    idOperationDocument: string
  ): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(
      `/operations/${idOperation}/documents/${idOperationDocument}/file`,
      { responseType: "blob" }
    );
  },

  async downloadAllOperationDocumentInZip(
    operation: OperationHeavy,
    documents: string[],
    shouldDownloadWithCategorizedDirectories: boolean
  ): Promise<{ id: string }> {
    const payload: OperationZipFileRequest = {
      idDocumentFilteredList: documents,
      shouldDownloadWithCategorizedDirectories,
    };
    const result = await apiRequest.post(
      `/operations/${operation.id}/documents/prepare-zip`,
      payload
    );

    return result.data;
  },

  downloadOperationArchive(
    idOperation: string
  ): Promise<ApiResponse<OperationZipFileResponse>> {
    return apiRequestThrottle.post(
      `archive/operations/${idOperation}/prepare-zip`
    );
  },

  async downloadQueuedOperationZipFile(
    idZipDocument: string,
    zipName: string
  ): Promise<void> {
    const res = await apiRequest.get(`/documents/${idZipDocument}`, {
      responseType: "blob",
    });
    downloadClickLink(res.data, {
      name: zipName,
    });
  },

  societeSupportUploadDocuments(
    idSocieteSupport: string,
    documentsToPost: CreationDocument[]
  ): Promise<ApiResponse<GedDocument[]>> {
    const formData: FormData = new FormData();

    const documentsToPostAsMap = documentMetadataListToMap(documentsToPost);

    formData.append("metadatas", JSON.stringify(documentsToPostAsMap));
    documentsToPost.forEach((fileData: CreationDocument) => {
      formData.append("files", fileData.file);
    });

    return apiRequestThrottle.post(
      `/societes-support/${idSocieteSupport}/documents`,
      formData,
      {
        validateStatus: function (status) {
          return status === 201 || status === 502 || status === 422;
        },
      }
    );
  },

  societeSupportFetchAllDocuments(
    idSocieteSupport: string
  ): Promise<ApiResponse<SocieteSupportDocument[]>> {
    return apiRequest.get(`/societes-support/${idSocieteSupport}/documents`);
  },

  downloadSocieteSupportDocument(
    idSocieteSupport: string,
    idOperationDocument: string
  ): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/documents/${idOperationDocument}/file`,
      { responseType: "blob" }
    );
  },

  societeSupportEditDocument(
    idSocieteSupport: string,
    editSocieteSupportDocument: DocumentUpdateRequest
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequestThrottle.patch(
      `/societes-support/${idSocieteSupport}/documents/${editSocieteSupportDocument.id}`,
      editSocieteSupportDocument
    );
  },

  societeSupportDeleteDocument(
    idSocieteSupport: string,
    idDocument: string
  ): Promise<ApiResponse<void>> {
    return apiRequest.delete(
      `/societes-support/${idSocieteSupport}/documents/${idDocument}`
    );
  },

  operationDocumentEditLink(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<EditionOperationDemandeDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/project/edit-link`
    );
  },

  operationBrouillonDocumentEditLink(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<EditionOperationDemandeDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/project/edit-brouillon-link`
    );
  },

  operationDocumentCheckBrouillon(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<DocumentEditionStatus>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/project/check-edit-brouillon`
    );
  },

  fetchUserEditingDocument(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<DemandeUserRoom[]>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/project/users`
    );
  },

  fetchESGEditLink(
    idOperation: string,
    idDocument: string
  ): Promise<ApiResponse<EditionOperationESGFormDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/esg/${idDocument}/edit-link`
    );
  },

  fetchESGBrouillonEditLink(
    idOperation: string,
    idDocument: string
  ): Promise<ApiResponse<EditionOperationESGFormDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/esg/${idDocument}/edit-brouillon-link`
    );
  },

  operationESGDocumentCheckBrouillon(
    idOperation: string,
    idDocument: string
  ): Promise<ApiResponse<DocumentEditionStatus>> {
    return apiRequest.get(
      `/operations/${idOperation}/esg/${idDocument}/check-edit-brouillon`
    );
  },

  fetchOperationPreviewLink(
    idOperation: string,
    idDocument: string
  ): Promise<ApiResponse<EditionOperationDemandeDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/documents/${idDocument}/preview-link`
    );
  },

  fetchSocieteSupportPreviewLink(
    idSocieteSupport: string,
    idDocument: string
  ): Promise<ApiResponse<EditionOperationDemandeDocument>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/documents/${idDocument}/preview-link`
    );
  },
  fetchDemandeWorkflowPreviewLink(
    idOperation: string,
    idDemande: string,
    idDocument: string
  ): Promise<ApiResponse<EditionOperationDemandeDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/documents/${idDocument}/preview-link`
    );
  },
  fetchLettreAccordWorkflowPreviewLink(
    idOperation: string,
    idLettreAccord: string,
    idDocument: string
  ): Promise<ApiResponse<EditionOperationLettreAccordDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettre-accord/${idLettreAccord}/documents/${idDocument}/preview-link`
    );
  },

  lettreAccordDocumentEditLink(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<EditionOperationLettreAccordDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettre-accord/${idLettreAccord}/project/edit-link`
    );
  },

  lettreAccordBrouillonDocumentEditLink(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<EditionOperationLettreAccordDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettre-accord/${idLettreAccord}/project/edit-brouillon-link`
    );
  },

  lettreAccordDocumentCheckBrouillon(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<DocumentEditionStatus>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettre-accord/${idLettreAccord}/project/check-edit-brouillon`
    );
  },

  fetchUserEditingLettreAccordDocument(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<LettreAccordUserRoom[]>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettre-accord/${idLettreAccord}/project/users`
    );
  },

  fetchSureteWorkflowPreviewLink(
    idSocieteSupport: string,
    idSurete: string,
    idDocument: string
  ): Promise<ApiResponse<EditionOperationDemandeDocument>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/documents/${idDocument}/preview-link`
    );
  },

  sureteDocumentEditLink(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<EditionSocieteSupportSureteDocument>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/project/edit-link`
    );
  },

  sureteBrouillonDocumentEditLink(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<EditionSocieteSupportSureteDocument>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/project/edit-brouillon-link`
    );
  },

  sureteDocumentCheckBrouillon(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<DocumentEditionStatus>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/project/check-edit-brouillon`
    );
  },

  fetchUserEditingSureteDocument(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<SureteUserRoom[]>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/project/users`
    );
  },

  fetchAccordCommercialWorkflowPreviewLink(
    idOperation: string,
    idAccordCommercial: string,
    idDocument: string
  ): Promise<ApiResponse<EditionOperationDemandeDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/accords-commerciaux/${idAccordCommercial}/documents/${idDocument}/preview-link`
    );
  },
};
