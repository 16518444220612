import { ApiResponse, apiRequest, apiRequestThrottle } from "@/apiRequest";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { createSuiviBudgetDocumentUploadPayloadMetadata } from "@/utils/documentUtils";
import { SuiviBudgetImportCreation } from "@/domain/suiviBudgetImportCreation";
import { SuiviBudgetResponse } from "@domain/dto/suiviBudgetResponse";
import { SuiviBudgetConfigurationCreationRequest } from "@domain/dto/suiviBudgetConfigurationCreationRequest";

export default {
  async uploadSuiviBudgetPromoteur(
    importCreation: SuiviBudgetImportCreation
  ): Promise<ApiResponse<PendingDocument[]>> {
    const formData: FormData = new FormData();

    formData.append(
      "metadata",
      createSuiviBudgetDocumentUploadPayloadMetadata(importCreation)
    );

    importCreation.documentList.forEach((document) => {
      formData.append("files", document.file);
    });
    return apiRequestThrottle.post(
      `/operations/${importCreation.idOperation}/suivi-budget/budget-promoteur/${importCreation.documentList.length}`,
      formData,
      {
        validateStatus: function (status) {
          return status === 201;
        },
      }
    );
  },

  async fetchAllForOperation(
    idOperation: string
  ): Promise<ApiResponse<SuiviBudgetResponse>> {
    return apiRequest.get(`/operations/${idOperation}/suivi-budget`, {
      validateStatus: function (status) {
        return status === 200;
      },
    });
  },

  async downloadSuiviBudget(
    idOperation: string
  ): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(`/operations/${idOperation}/suivi-budget/export`, {
      validateStatus: function (status) {
        return status === 200;
      },
    });
  },

  async fetchSuiviBudgetImportDocumentContent(
    idOperation: string,
    idSuiviBudgetImport: string
  ): Promise<ApiResponse<string[][]>> {
    return apiRequest.get(
      `/operations/${idOperation}/suivi-budget/budget-promoteur/${idSuiviBudgetImport}`
    );
  },

  async createSuiviBudgetConfiguration(
    budgetConfiguration: SuiviBudgetConfigurationCreationRequest
  ): Promise<ApiResponse<string>> {
    return apiRequest.post(
      `/operations/${budgetConfiguration.idOperation}/suivi-budget/configuration`,
      budgetConfiguration
    );
  },

  downloadSuiviBudgetDocumentsPromoteur(
    idOperation: string
  ): Promise<ApiResponse<string>> {
    return apiRequest.post(
      `/operations/${idOperation}/suivi-budget/documents-promoteur`,
      {},
      {
        validateStatus: function (status) {
          return status === 200 || status === 409;
        },
      }
    );
  },
};
