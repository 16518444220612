import { Cell } from "@/domain/cell";
import { CommercialisationImportConfiguration } from "@/domain/commercialisationImportConfiguration";
import { EtapeCommercialisationImport } from "@/domain/enum/etapeCommercialisationImport";
import { CommercialisationCreateRequest } from "@domain/dto/commercialisationCreateRequest";
import { CommercialisationPreviewRow } from "@/domain/commercialisationPreviewRow";
import { v4 as uuid } from "uuid";
import { SuiviCommercialisationTableauLigne } from "@domain/dto/suiviCommercialisationTableauLigne";
import { CommercialisationOperationDemande } from "@/domain/commercialisationOperationDemande";
import groupBy from "lodash/groupBy";
import {
  computeVariationPourcentageValues,
  convertCentimeAmountToEuroAmount,
  convertEuroToCentime,
  formatNumberToFrenchString,
  isNumber,
  sum,
} from "@/utils/numberUtils";
import { ChoixUniteEuroCommercialisationImport } from "@domain/enum/choixUniteEuroCommercialisationImport";
import { isPositiveInteger } from "@/utils/validationFormUtils";
import { SuiviCommercialisationUpdateRequest } from "@domain/dto/suiviCommercialisationUpdateRequest";
import { SuiviCommercialisationTrancheDuplicates } from "@domain/dto/suiviCommercialisationTrancheDuplicates";
import { GroupLite } from "@domain/dto/groupLite";
import { hasRole } from "./groupUtils";
import { Role } from "@domain/enum/role";
import { User } from "@domain/dto/user";
import { CommercialisationOperationTranche } from "@/domain/commercialisationOperationTranche";
import { SuiviCommercialisationTableauLigneOperation } from "@domain/dto/suiviCommercialisationTableauLigneOperation";
import { SuiviCommercialisationTableauTrancheLigne } from "@domain/dto/suiviCommercialisationTableauTrancheLigne";
import { SuiviVariation, VariationType } from "@/domain/suiviVariation";
import { computeVariationRatio } from "@/utils/suiviBudgetUtils";
import { OperationSuiviCommercialisationTotal } from "@domain/dto/operationSuiviCommercialisation";

export enum VariationInterest {
  UP_IS_GOOD = "UP_IS_GOOD",
  UP_IS_BAD = "UP_IS_BAD",
}

export function convertToCellList(data: string[][]): Cell[] {
  const cells: Cell[] = [];
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].length; j++) {
      cells.push({ row: i, column: j, value: data[i][j] });
    }
  }
  return cells;
}

export function getHeaders(cells: Cell[]): Cell[] {
  return cells.filter((cell) => cell.row === 0);
}

export function getDataCells(cells: Cell[]): Cell[] {
  return cells.filter((cell) => cell.row > 0);
}

const etapeObligatoireList: EtapeCommercialisationImport[] = [
  EtapeCommercialisationImport.CHOIX_PREMIERE_LIGNE,
  EtapeCommercialisationImport.CHOIX_COLONNE_IDENTIFIANT,
  EtapeCommercialisationImport.CHOIX_COLONNE_TRANCHE,
  EtapeCommercialisationImport.CHOIX_CA_TTC_TOTAL,
  EtapeCommercialisationImport.CHOIX_CA_TTC_ACTE,
  EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_TOTAL,
  EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_ACTES,
];

const etapeFacultativeList: EtapeCommercialisationImport[] = [
  EtapeCommercialisationImport.CHOIX_CA_TTC_RESERVE,
  EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_RESERVES,
];

export function getEtapeActive(
  configuration: CommercialisationImportConfiguration
): EtapeCommercialisationImport {
  if (!Number.isInteger(configuration.premiereLigne)) {
    return EtapeCommercialisationImport.CHOIX_PREMIERE_LIGNE;
  } else if (
    !Number.isInteger(configuration.numeroActe) &&
    !Number.isInteger(configuration.referencePromoteur)
  ) {
    return EtapeCommercialisationImport.CHOIX_COLONNE_IDENTIFIANT;
  } else if (!Number.isInteger(configuration.tranche)) {
    return EtapeCommercialisationImport.CHOIX_COLONNE_TRANCHE;
  } else if (
    !Number.isInteger(configuration.euroCentimeChiffreAffaireTtcTotal)
  ) {
    return EtapeCommercialisationImport.CHOIX_CA_TTC_TOTAL;
  } else if (
    !Number.isInteger(configuration.euroCentimeChiffreAffaireTtcActe)
  ) {
    return EtapeCommercialisationImport.CHOIX_CA_TTC_ACTE;
  } else if (!Number.isInteger(configuration.nombreLotTotal)) {
    return EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_TOTAL;
  } else if (!Number.isInteger(configuration.nombreLotActe)) {
    return EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_ACTES;
  } else if (
    !Number.isInteger(configuration.euroCentimeChiffreAffaireTtcReserve)
  ) {
    return EtapeCommercialisationImport.CHOIX_CA_TTC_RESERVE;
  } else if (!Number.isInteger(configuration.nombreLotReserve)) {
    return EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_RESERVES;
  }
  return EtapeCommercialisationImport.CHOIX_ETAPE_NON_DEFINIE;
}

export function getEtapeLabel(etape: EtapeCommercialisationImport): string {
  switch (etape) {
    case EtapeCommercialisationImport.CHOIX_PREMIERE_LIGNE:
      return "En-têtes de colonnes";
    case EtapeCommercialisationImport.CHOIX_COLONNE_IDENTIFIANT: {
      return "";
    }
    case EtapeCommercialisationImport.CHOIX_COLONNE_TRANCHE:
      return "Tranche";
    case EtapeCommercialisationImport.CHOIX_CA_TTC_TOTAL:
      return "CA TTC Total";
    case EtapeCommercialisationImport.CHOIX_CA_TTC_ACTE:
      return "CA TTC Acté";
    case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_TOTAL:
      return "Nombre de lots total";
    case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_ACTES:
      return "Nombre de lots actés";
    case EtapeCommercialisationImport.CHOIX_CA_TTC_RESERVE:
      return "CA TTC Réservé";
    case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_RESERVES:
      return "Nb de lots réservés";
    default:
      return "unknown étape";
  }
}

export function getEtapeNumero(etape: EtapeCommercialisationImport): number {
  const obligatoireIndex = etapeObligatoireList.findIndex(
    (obligatoire) => obligatoire === etape
  );
  const facultatifIndex = etapeFacultativeList.findIndex(
    (facultatif) => facultatif === etape
  );
  if (obligatoireIndex > -1) {
    return obligatoireIndex + 1;
  } else if (facultatifIndex > -1) {
    return etapeObligatoireList.length + facultatifIndex + 1;
  } else {
    return 0;
  }
}

export function getEtapeFromNumero(
  numero: number
): EtapeCommercialisationImport {
  const index = numero - 1;
  if (index < etapeObligatoireList.length) {
    return etapeObligatoireList[index];
  } else if (index < getImportNombreEtapeTotal()) {
    const indexFacultatif = index - etapeObligatoireList.length;
    return etapeFacultativeList[indexFacultatif];
  } else {
    return EtapeCommercialisationImport.CHOIX_ETAPE_NON_DEFINIE;
  }
}

export function isEtapeCompleted(
  configuration: CommercialisationImportConfiguration,
  etape: EtapeCommercialisationImport
): boolean {
  switch (etape) {
    case EtapeCommercialisationImport.CHOIX_PREMIERE_LIGNE:
      return Number.isInteger(configuration.premiereLigne);
    case EtapeCommercialisationImport.CHOIX_COLONNE_IDENTIFIANT:
      return (
        Number.isInteger(configuration.numeroActe) ||
        Number.isInteger(configuration.referencePromoteur)
      );
    case EtapeCommercialisationImport.CHOIX_COLONNE_TRANCHE:
      return Number.isInteger(configuration.tranche);
    case EtapeCommercialisationImport.CHOIX_CA_TTC_TOTAL:
      return Number.isInteger(configuration.euroCentimeChiffreAffaireTtcTotal);
    case EtapeCommercialisationImport.CHOIX_CA_TTC_ACTE:
      return Number.isInteger(configuration.euroCentimeChiffreAffaireTtcActe);
    case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_TOTAL:
      return Number.isInteger(configuration.nombreLotTotal);
    case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_ACTES:
      return Number.isInteger(configuration.nombreLotActe);
    case EtapeCommercialisationImport.CHOIX_CA_TTC_RESERVE:
      return Number.isInteger(
        configuration.euroCentimeChiffreAffaireTtcReserve
      );
    case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_RESERVES:
      return Number.isInteger(configuration.nombreLotReserve);
    default:
      return true;
  }
}

export function getImportNombreEtapeTotal(): number {
  return etapeObligatoireList.length + etapeFacultativeList.length;
}

export function isCommercialisationImportReady(
  configuration: CommercialisationImportConfiguration
): boolean {
  return (
    !!configuration &&
    isCommercialisationChoixIdentifiantReady(configuration) &&
    isPositiveInteger(configuration.tranche) &&
    isPositiveInteger(configuration.euroCentimeChiffreAffaireTtcTotal) &&
    isPositiveInteger(configuration.euroCentimeChiffreAffaireTtcActe) &&
    isPositiveInteger(configuration.nombreLotTotal) &&
    isPositiveInteger(configuration.nombreLotActe)
  );
}

function isCommercialisationChoixIdentifiantReady(
  configuration: CommercialisationImportConfiguration
): boolean {
  return (
    isPositiveInteger(configuration.numeroActe) ||
    isPositiveInteger(configuration.referencePromoteur)
  );
}

function isCommercialisationChoixChiffreAffaireKiloEuro(
  configuration: CommercialisationImportConfiguration
): boolean {
  return (
    configuration.choixChiffreAffaire ===
    ChoixUniteEuroCommercialisationImport.KILO_EURO
  );
}

export function excludeBeforeFirstLineFromRawMatrix(
  matrix: string[][],
  configuration: CommercialisationImportConfiguration
): string[][] {
  if (
    isPositiveInteger(configuration.premiereLigne) &&
    configuration.premiereLigne > 0
  ) {
    const croppedMatrix = [...matrix];
    croppedMatrix.splice(0, configuration.premiereLigne);
    return croppedMatrix;
  }
  return matrix;
}

export function excludeFirstLineFromRawMatrix(
  matrix: string[][],
  configuration: CommercialisationImportConfiguration
): string[][] {
  if (isPositiveInteger(configuration.premiereLigne)) {
    const croppedMatrix = [...matrix];
    croppedMatrix.splice(0, configuration.premiereLigne + 1);
    return croppedMatrix;
  }
  return matrix;
}

function formatNombreLot(
  nombreLotIndex: number | undefined,
  row: string[]
): number {
  if (isPositiveInteger(nombreLotIndex)) {
    if (!row[nombreLotIndex]) {
      return 0;
    }
    return parseInt(row[nombreLotIndex]);
  }
  return 0;
}

function formatChiffreAffaire(
  configuration: CommercialisationImportConfiguration,
  row: string[],
  chiffreAffaireIndex: number | undefined
): number {
  if (isPositiveInteger(chiffreAffaireIndex)) {
    if (!row[chiffreAffaireIndex]) {
      return 0;
    }

    return convertEuroToCentime(
      +row[chiffreAffaireIndex],
      isCommercialisationChoixChiffreAffaireKiloEuro(configuration)
    );
  }
  return 0;
}

export function convertImportMatrixRowToCreationRequest(
  row: string[],
  configuration: CommercialisationImportConfiguration
): CommercialisationCreateRequest {
  return {
    numeroActe: isPositiveInteger(configuration.numeroActe)
      ? row[configuration.numeroActe]
      : undefined,
    referencePromoteur: isPositiveInteger(configuration.referencePromoteur)
      ? row[configuration.referencePromoteur]
      : undefined,
    tranche: isPositiveInteger(configuration.tranche)
      ? row[configuration.tranche]
      : "",
    euroCentimeChiffreAffaireTtcTotal: formatChiffreAffaire(
      configuration,
      row,
      configuration.euroCentimeChiffreAffaireTtcTotal
    ),
    euroCentimeChiffreAffaireTtcActe: formatChiffreAffaire(
      configuration,
      row,
      configuration.euroCentimeChiffreAffaireTtcActe
    ),
    euroCentimeChiffreAffaireTtcReserve: formatChiffreAffaire(
      configuration,
      row,
      configuration.euroCentimeChiffreAffaireTtcReserve
    ),
    nombreLotTotal: formatNombreLot(configuration.nombreLotTotal, row),
    nombreLotActe: formatNombreLot(configuration.nombreLotActe, row),
    nombreLotReserve: formatNombreLot(configuration.nombreLotReserve, row),
  };
}

export function isTruthyString(value: unknown): boolean {
  return typeof value === "string" && value.trim() !== "";
}

export function isCorrectPreviewLine(
  line: string[],
  importConfig: CommercialisationImportConfiguration
): boolean {
  const trancheIndex = importConfig.tranche;
  const chiffreAffaireTTCTotalIndex =
    importConfig.euroCentimeChiffreAffaireTtcTotal;

  if (
    isPositiveInteger(trancheIndex) &&
    isPositiveInteger(chiffreAffaireTTCTotalIndex)
  ) {
    return (
      isTruthyString(line[trancheIndex]) &&
      !!line[chiffreAffaireTTCTotalIndex] &&
      Math.abs(Number(line[chiffreAffaireTTCTotalIndex])) >= 0
    );
  }

  return false;
}

export function filterIncorrectPreviewRows(
  matrix: CommercialisationPreviewRow[]
): CommercialisationPreviewRow[] {
  return matrix.filter((row) => {
    return (
      isTruthyString(row.tranche) &&
      row.euroCentimeChiffreAffaireTtcTotal &&
      Math.abs(Number(row.euroCentimeChiffreAffaireTtcTotal)) >= 0
    );
  });
}

export function convertImportMatrixToPreviewList(
  matrix: string[][],
  configuration: CommercialisationImportConfiguration
): CommercialisationPreviewRow[] {
  return matrix.map((row) =>
    convertImportMatrixRowToPreviewRow(row, configuration)
  );
}

export function sanitizeLine(columnList: string[]): string[] {
  columnList = columnList.map((column) => column.replace("€", ""));

  columnList = columnList.map((column) => {
    const commaMatches = [...column.matchAll(/,/g)];
    const hasDecimalPoint = [...column.matchAll(/\./g)];
    switch (commaMatches.length) {
      case 1:
        if (hasDecimalPoint.length) {
          column = column.substring(0, hasDecimalPoint[0].index);
        } else {
          column = column.substring(0, commaMatches[0].index);
        }
      case 2:
        column = column.replaceAll(",", "");
        break;
    }

    if (!commaMatches.length && hasDecimalPoint.length) {
      column = column.substring(0, hasDecimalPoint[0].index);
    }

    return column.trim();
  });
  return columnList;
}

export function convertImportMatrixRowToPreviewRow(
  row: string[],
  configuration: CommercialisationImportConfiguration
): CommercialisationPreviewRow {
  row = sanitizeLine(row);
  return {
    id: uuid(),
    numeroActe: isPositiveInteger(configuration.numeroActe)
      ? row[configuration.numeroActe]
      : undefined,
    referencePromoteur: isPositiveInteger(configuration.referencePromoteur)
      ? row[configuration.referencePromoteur]
      : undefined,
    tranche: isPositiveInteger(configuration.tranche)
      ? row[configuration.tranche]
      : undefined,
    euroCentimeChiffreAffaireTtcTotal: isPositiveInteger(
      configuration.euroCentimeChiffreAffaireTtcTotal
    )
      ? row[configuration.euroCentimeChiffreAffaireTtcTotal]
      : undefined,
    euroCentimeChiffreAffaireTtcActe: isPositiveInteger(
      configuration.euroCentimeChiffreAffaireTtcActe
    )
      ? row[configuration.euroCentimeChiffreAffaireTtcActe]
      : undefined,
    nombreLotTotal: isPositiveInteger(configuration.nombreLotTotal)
      ? row[configuration.nombreLotTotal]
      : undefined,
    nombreLotActe: isPositiveInteger(configuration.nombreLotActe)
      ? row[configuration.nombreLotActe]
      : undefined,
    euroCentimeChiffreAffaireTtcReserve: isPositiveInteger(
      configuration.euroCentimeChiffreAffaireTtcReserve
    )
      ? row[configuration.euroCentimeChiffreAffaireTtcReserve]
      : undefined,
    nombreLotReserve: isPositiveInteger(configuration.nombreLotReserve)
      ? row[configuration.nombreLotReserve]
      : undefined,
  };
}

export function truncateCommercialisationPreviewData(
  rowList: CommercialisationPreviewRow[]
): CommercialisationPreviewRow[] {
  return rowList.slice(0, 50);
}

export function getCommercialisationConfiguredColumnList(
  configuration: CommercialisationImportConfiguration
): number[] {
  const columnList = [];
  if (isPositiveInteger(configuration.numeroActe)) {
    columnList.push(configuration.numeroActe);
  }
  if (isPositiveInteger(configuration.referencePromoteur)) {
    columnList.push(configuration.referencePromoteur);
  }
  if (isPositiveInteger(configuration.tranche)) {
    columnList.push(configuration.tranche);
  }
  if (isPositiveInteger(configuration.numeroActe)) {
    columnList.push(configuration.numeroActe);
  }
  if (isPositiveInteger(configuration.euroCentimeChiffreAffaireTtcTotal)) {
    columnList.push(configuration.euroCentimeChiffreAffaireTtcTotal);
  }
  if (isPositiveInteger(configuration.euroCentimeChiffreAffaireTtcActe)) {
    columnList.push(configuration.euroCentimeChiffreAffaireTtcActe);
  }
  if (isPositiveInteger(configuration.nombreLotTotal)) {
    columnList.push(configuration.nombreLotTotal);
  }
  if (isPositiveInteger(configuration.nombreLotActe)) {
    columnList.push(configuration.nombreLotActe);
  }
  if (isPositiveInteger(configuration.euroCentimeChiffreAffaireTtcReserve)) {
    columnList.push(configuration.euroCentimeChiffreAffaireTtcReserve);
  }
  if (isPositiveInteger(configuration.nombreLotReserve)) {
    columnList.push(configuration.nombreLotReserve);
  }

  return columnList;
}

export function getCommercialisationOperationBanqueForUser(
  tableauOperationList: SuiviCommercialisationTableauLigneOperation[],
  user: User
): GroupLite {
  const operationBanqueList = tableauOperationList.map((ligne) => ligne.banque);
  const userBanqueList = user.groups.filter((group) =>
    hasRole(group.roles, Role.BANQUE)
  );

  const userOperationBanqueList = operationBanqueList.filter((banque) =>
    userBanqueList.some((userBanque) => userBanque.id === banque.id)
  );

  const banqueOnlyGroup = userOperationBanqueList.find(
    (banque) =>
      hasRole(banque.roles, Role.BANQUE) && !hasRole(banque.roles, Role.POOL)
  );

  if (banqueOnlyGroup) {
    return banqueOnlyGroup;
  }

  const banqueGroup = userOperationBanqueList.find((banque) =>
    hasRole(banque.roles, Role.BANQUE)
  );

  if (banqueGroup) {
    return banqueGroup;
  }

  return operationBanqueList[0];
}

export function arrangeCommercialisationOperationTranche(
  tableau: SuiviCommercialisationTableauTrancheLigne[],
  user: User
): CommercialisationOperationTranche[] {
  if (!tableau.length) {
    return [];
  }

  const lignesByOperation = groupBy(tableau, (ligne) => ligne.operation.id);

  return Object.values(lignesByOperation).map((lignes) => {
    const firstTrancheForOperation = lignes[0];
    const banque = getCommercialisationOperationBanqueForUser(
      lignes.map((ligne) => ligne.operation),
      user
    );

    const tranche: CommercialisationOperationTranche = {
      operation: {
        id: firstTrancheForOperation.operation.id,
        name: firstTrancheForOperation.operation.name,
        banque: banque,
        referencePromoteur:
          firstTrancheForOperation.operation.referencePromoteur,
        euroCentimeChiffreAffaireTtcTotal: computeValueForOperation(
          lignes,
          (ligne) => ligne.tranche.euroCentimeChiffreAffaireTtcTotal
        ),
        euroCentimeChiffreAffaireTtcActe: computeValueForOperation(
          lignes,
          (ligne) => ligne.tranche.euroCentimeChiffreAffaireTtcActe
        ),
        euroCentimeChiffreAffaireTtcReserve: computeValueForOperation(
          lignes,
          (ligne) => ligne.tranche.euroCentimeChiffreAffaireTtcReserve
        ),
        nombreLotTotal: computeValueForOperation(
          lignes,
          (ligne) => ligne.tranche.nombreLotTotal
        ),
        nombreLotReserve: computeValueForOperation(
          lignes,
          (ligne) => ligne.tranche.nombreLotReserve
        ),
        nombreLotActe: computeValueForOperation(
          lignes,
          (ligne) => ligne.tranche.nombreLotActe
        ),
        nombreLotsStock: computeValueForOperation(lignes, (ligne) =>
          computeNombreLotStockForLigne(ligne.tranche)
        ),
        euroCentimeChiffreAffaireTtcStock: computeValueForOperation(
          lignes,
          (ligne) => computeChiffreAffaireStockForLigne(ligne.tranche)
        ),
      },
      trancheList: lignes.map((ligne) => {
        const tranche = ligne.tranche;
        const { demandeList: _demandeList, ...trancheWithoutDemandeList } =
          tranche;
        return {
          ...trancheWithoutDemandeList,
          euroCentimeChiffreAffaireTtcStock:
            computeChiffreAffaireStockForLigne(tranche),
          nombreLotsStock: computeNombreLotStockForLigne(tranche),
        };
      }),
    };

    return tranche;
  });
}

export function arrangeCommercialisationOperationDemande(
  tableau: SuiviCommercialisationTableauLigne[],
  user: User
): CommercialisationOperationDemande[] {
  const lignesByOperation = groupBy(tableau, (ligne) => ligne.operation.id);
  return Object.keys(lignesByOperation).map((idOperation) => {
    const lignes = lignesByOperation[idOperation];
    const firstDemandeForOperation = lignes[0];
    const banque = getCommercialisationOperationBanqueForUser(
      lignes.map((ligne) => ligne.operation),
      user
    );
    return {
      operation: {
        id: firstDemandeForOperation.operation.id,
        name: firstDemandeForOperation.operation.name,
        banque: banque,
        referencePromoteur:
          firstDemandeForOperation.operation.referencePromoteur,
        euroCentimeChiffreAffaireTtcTotal:
          computeChiffreAffaireTtcTotalForOperation(lignes),
        euroCentimeChiffreAffaireTtcActe:
          computeChiffreAffaireTtcActeForOperation(lignes),
        euroCentimeChiffreAffaireTtcReserve:
          computeChiffreAffaireTtcReserveForOperation(lignes),
        euroCentimeChiffreAffaireTtcStock:
          computeChiffreAffaireTtcStockForOperation(lignes),
        nombreLotTotal: computeNombreLotTotalForOperation(lignes),
        nombreLotReserve: computeNombreLotReserveForOperation(lignes),
        nombreLotActe: computeNombreLotActeForOperation(lignes),
        nombreLotsStock: computeNombreLotsStockForOperation(lignes),
      },
      demandeList: lignes.map((l) => ({
        id: l.demande.id,
        name: l.demande.name,
        numeroActe: l.demande.numeroActe,
        tranche: l.demande.tranche,
        euroCentimeChiffreAffaireTtcTotal:
          l.demande.euroCentimeChiffreAffaireTtcTotal,
        euroCentimeChiffreAffaireTtcActe:
          l.demande.euroCentimeChiffreAffaireTtcActe,
        euroCentimeChiffreAffaireTtcReserve:
          l.demande.euroCentimeChiffreAffaireTtcReserve,
        euroCentimeChiffreAffaireTtcStock: computeChiffreAffaireStockForLigne({
          euroCentimeChiffreAffaireTtcTotal:
            l.demande.euroCentimeChiffreAffaireTtcTotal,
          euroCentimeChiffreAffaireTtcActe:
            l.demande.euroCentimeChiffreAffaireTtcActe,
          euroCentimeChiffreAffaireTtcReserve:
            l.demande.euroCentimeChiffreAffaireTtcReserve,
        }),
        nombreLotTotal: l.demande.nombreLotTotal,
        nombreLotReserve: l.demande.nombreLotReserve,
        nombreLotActe: l.demande.nombreLotActe,
        nombreLotsStock: computeNombreLotStockForLigne({
          nombreLotTotal: l.demande.nombreLotTotal,
          nombreLotActe: l.demande.nombreLotActe,
          nombreLotReserve: l.demande.nombreLotReserve,
        }),
        updatedAt: l.demande.updatedAt,
        signedAt: l.demande.signedAt,
      })),
    };
  });
}

export function computeChiffreAffaireStockForLigne(ligne: {
  euroCentimeChiffreAffaireTtcTotal?: number;
  euroCentimeChiffreAffaireTtcActe?: number;
  euroCentimeChiffreAffaireTtcReserve?: number;
}): number {
  return computeStock({
    total: ligne.euroCentimeChiffreAffaireTtcTotal,
    acte: ligne.euroCentimeChiffreAffaireTtcActe,
    reserve: ligne.euroCentimeChiffreAffaireTtcReserve,
  });
}

export function computeNombreLotStockForLigne(ligne: {
  nombreLotTotal?: number;
  nombreLotActe?: number;
  nombreLotReserve?: number;
}): number {
  return computeStock({
    total: ligne.nombreLotTotal,
    acte: ligne.nombreLotActe,
    reserve: ligne.nombreLotReserve,
  });
}

function computeStock(demande: {
  total?: number;
  acte?: number;
  reserve?: number;
}): number {
  if (!demande.total || !demande.acte) {
    return 0;
  }
  const chiffreAffaireTtcTotal = demande.total;
  const chiffreAffaireTtcActe = demande.acte;
  if (isNumber(chiffreAffaireTtcTotal) && isNumber(chiffreAffaireTtcActe)) {
    if (!!demande.reserve && isNumber(demande.reserve)) {
      const chiffreAffaireTtcReserve = demande.reserve;
      return (
        chiffreAffaireTtcTotal -
        chiffreAffaireTtcActe -
        chiffreAffaireTtcReserve
      );
    } else {
      return chiffreAffaireTtcTotal - chiffreAffaireTtcActe;
    }
  } else {
    return 0;
  }
}

export function computeChiffreAffaireTtcTotalForOperation(
  lignes: SuiviCommercialisationTableauLigne[]
): number {
  return computeValueForOperation(
    lignes,
    (ligne) => ligne.demande.euroCentimeChiffreAffaireTtcTotal
  );
}

function computeChiffreAffaireTtcActeForOperation(
  lignes: SuiviCommercialisationTableauLigne[]
): number {
  return computeValueForOperation(
    lignes,
    (ligne) => ligne.demande.euroCentimeChiffreAffaireTtcActe
  );
}

function computeChiffreAffaireTtcReserveForOperation(
  lignes: SuiviCommercialisationTableauLigne[]
): number {
  return computeValueForOperation(
    lignes,
    (ligne) => ligne.demande.euroCentimeChiffreAffaireTtcReserve
  );
}

function computeNombreLotTotalForOperation(
  lignes: SuiviCommercialisationTableauLigne[]
): number {
  return computeValueForOperation(
    lignes,
    (ligne) => ligne.demande.nombreLotTotal
  );
}

function computeNombreLotReserveForOperation(
  lignes: SuiviCommercialisationTableauLigne[]
): number {
  return computeValueForOperation(
    lignes,
    (ligne) => ligne.demande.nombreLotReserve
  );
}

function computeNombreLotActeForOperation(
  lignes: SuiviCommercialisationTableauLigne[]
): number {
  return computeValueForOperation(
    lignes,
    (ligne) => ligne.demande.nombreLotActe
  );
}

function computeValueForOperation<T>(
  lignes: T[],
  fn: (ligne: T) => number | undefined
): number {
  const numbers = lignes.map((ligne) => {
    const value = fn(ligne);
    return value ?? null;
  });
  return sum(numbers.filter((number): number is number => number !== null));
}

export function computeChiffreAffaireTtcStockForOperation(
  lignes: SuiviCommercialisationTableauLigne[]
): number {
  return sum(
    lignes.map((ligne) => computeChiffreAffaireStockForLigne(ligne.demande))
  );
}

export function computeNombreLotsStockForOperation(
  lignes: SuiviCommercialisationTableauLigne[]
): number {
  return sum(
    lignes.map((ligne) => {
      return computeNombreLotStockForLigne(ligne.demande);
    })
  );
}

export function computeChiffreAffaireTtcTotalAllOperations(
  tableau: CommercialisationOperationDemande[]
): number {
  return sum(
    tableau.map((ligne) =>
      convertCentimeAmountToEuroAmount(
        Number(ligne.operation.euroCentimeChiffreAffaireTtcTotal)
      )
    )
  );
}

export function computeNombreLotTotalAllOperations(
  tableau: CommercialisationOperationDemande[]
): number {
  return sum(
    tableau
      .map((ligne) => ligne.operation.nombreLotTotal)
      .filter(
        (nombreLotTotal): nombreLotTotal is number => nombreLotTotal !== null
      )
  );
}

export function computeNombreOperations(
  tableau: CommercialisationOperationDemande[]
): number {
  return tableau.length;
}

export function checkDataValid(
  commercialisationCreateRequestList: CommercialisationCreateRequest[]
): { isDataValid: boolean; errorType?: string; errorLineNumber?: number } {
  for (const [
    index,
    commercialisationCreateRequest,
  ] of commercialisationCreateRequestList.entries()) {
    if (
      isNaN(
        commercialisationCreateRequest.euroCentimeChiffreAffaireTtcActe ?? NaN
      ) ||
      (commercialisationCreateRequest.euroCentimeChiffreAffaireTtcReserve &&
        isNaN(
          commercialisationCreateRequest.euroCentimeChiffreAffaireTtcReserve
        )) ||
      isNaN(
        commercialisationCreateRequest.euroCentimeChiffreAffaireTtcTotal ?? NaN
      ) ||
      (commercialisationCreateRequest.nombreLotTotal &&
        isNaN(commercialisationCreateRequest.nombreLotTotal)) ||
      (commercialisationCreateRequest.nombreLotActe &&
        isNaN(commercialisationCreateRequest.nombreLotActe)) ||
      (commercialisationCreateRequest.nombreLotReserve &&
        isNaN(commercialisationCreateRequest.nombreLotReserve))
    ) {
      return {
        isDataValid: false,
        errorType: "Not a number",
        errorLineNumber: index + 1,
      };
    }
  }
  return {
    isDataValid: true,
  };
}

export function getTestMatrix(): string[][] {
  return [
    [
      "Numéro d’acte",
      "CA TTC total",
      "CA TTC acté",
      "CA TTC réservé",
      "CA TTC stock",
      "NDL total",
      "NDL acté",
      "NDL réservé",
      "NDL stock",
      "Tranche",
      "colonne fantoche 1",
      "colonne fantoche 2",
      "colonne fantoche 3",
    ],
    [
      "Acte1Scene3",
      "1550000",
      "7500000",
      "155000",
      "13232323",
      "85",
      "42",
      "42",
      "42",
      "18b",
      "4334434",
      "43434343",
      "43343434",
    ],
    [
      "",
      "1550001",
      "7500001",
      "155001",
      "13232324",
      "85",
      "43",
      "43",
      "43",
      "309",
      "290-767C97",
      "43434344",
      "43343435",
    ],
    [
      "IYACCV",
      "1550002",
      "7500002",
      "",
      "",
      "85",
      "44",
      "44",
      "44",
      "C66",
      "4334436",
      "43434345",
      "43343436",
    ],
    [
      "IYATFD",
      "1550003",
      "",
      "155003",
      "13232326",
      "85",
      "45",
      "45",
      "45",
      "21c",
      "4334437",
      "",
      "43343437",
    ],
    [
      "XAAPLT",
      "",
      "7500004",
      "155004",
      "13232327",
      "85",
      "46",
      "46",
      "46",
      "",
      "4334438",
      "43434347",
      "43343438",
    ],
    [
      "ZOZO",
      "1550004",
      "7500004",
      "155004",
      "13232327",
      "85",
      "46",
      "46",
      "46",
      "21c",
      "4334438",
      "43434347",
      "43343438",
    ],
    [
      "Ligne 114",
      "1550004",
      "7500004",
      "155004",
      "13232327",
      "85",
      "46",
      "46",
      "46",
      "3290",
      "4334438",
      "43434347",
      "43343438",
    ],
  ];
}

export function detectEmptyColumns(matrix: string[][]): number[] {
  const matrixHeight = matrix.length;
  const matrixWidth = matrix[0].length;
  const emptyIndexColumn: number[] = [];
  for (let col = 0; col < matrixWidth; col++) {
    let ligne = 1;
    while (ligne < matrixHeight && matrix[ligne][col] === "") {
      ligne++;
    }
    if (ligne >= matrixHeight) {
      emptyIndexColumn.push(col);
    }
  }
  return emptyIndexColumn;
}

export function removeEmptyColumns(matrix: string[][]): string[][] {
  const matrixWithoutEmptyCol: string[][] = [];

  if (matrix.length) {
    const emptyIndexColumn = detectEmptyColumns(matrix);
    for (const column of matrix) {
      const columnsData = [];
      for (let row = 0; row < column.length; row++) {
        if (!emptyIndexColumn.includes(row)) {
          columnsData.push(column[row]);
        }
      }
      matrixWithoutEmptyCol.push(columnsData);
    }
  }

  return matrixWithoutEmptyCol;
}

export function isUpdateStringValid(value: string | undefined | null): boolean {
  return !!value;
}

export function pluralize(list: any[], word: string): string {
  if (list.length > 1) {
    return `${word}s`;
  }
  return word;
}

export function areAllUpdateStringsValid(
  suiviCommUpdateRequestList: SuiviCommercialisationUpdateRequest[]
) {
  return suiviCommUpdateRequestList.every((request) => {
    return (
      isUpdateStringValid(request.referencePromoteur) ||
      isUpdateStringValid(request.numeroActe)
    );
  });
}

export function getSanitizedMatrix(
  matrix: string[][],
  importConfig: CommercialisationImportConfiguration
): string[][] {
  return matrix
    .map((row) => sanitizeLine(row))
    .filter((line) => isCorrectPreviewLine(line, importConfig));
}

export function extractTrancheDuplicates(
  createRequestList: CommercialisationCreateRequest[]
): SuiviCommercialisationTrancheDuplicates {
  // Si l'import se fait à partir des numéros d'actes, pas de duplication de tranche possible
  if (createRequestList[0]?.numeroActe || !createRequestList.length) {
    return {
      filteredCreationRequestList: createRequestList,
      referenceTrancheDuplicateErrors: [],
    };
  }

  const createRequestListByReferenceTranche = groupBy(
    createRequestList,
    (item) => `${item.referencePromoteur}_${item.tranche}`
  );

  const trancheDuplicates: SuiviCommercialisationTrancheDuplicates = {
    filteredCreationRequestList: [],
    referenceTrancheDuplicateErrors: [],
  };

  Object.keys(createRequestListByReferenceTranche).forEach(
    (referenceTranche) => {
      if (createRequestListByReferenceTranche[referenceTranche].length === 1) {
        trancheDuplicates.filteredCreationRequestList.push(
          createRequestListByReferenceTranche[referenceTranche][0]
        );
      } else {
        const [referencePromoteur, tranche] = referenceTranche.split("_");
        trancheDuplicates.referenceTrancheDuplicateErrors.push({
          referencePromoteur,
          tranche,
        });
      }
    }
  );

  return trancheDuplicates;
}

export function computeVariationType(ratio: number): VariationType {
  if (ratio > 0) {
    return VariationType.GOOD;
  } else if (ratio < 0) {
    return VariationType.BAD;
  } else {
    return VariationType.NONE;
  }
}

export function computeReverseVariationType(ratio: number): VariationType {
  if (ratio > 0) {
    return VariationType.BAD;
  } else if (ratio < 0) {
    return VariationType.GOOD;
  } else {
    return VariationType.NONE;
  }
}

export function computeSuiviVariation(
  previousValue: number,
  currentValue: number,
  progressionInterest: VariationInterest,
  tooltipContent?: string
): SuiviVariation | undefined {
  const ratio = computeVariationRatio(previousValue, currentValue);

  return {
    variationType:
      progressionInterest === VariationInterest.UP_IS_GOOD
        ? computeVariationType(ratio)
        : computeReverseVariationType(ratio),
    variationPourcentage: computeVariationPourcentageValues(
      previousValue,
      currentValue
    ),
    ratio,
    tooltipContent,
  };
}

export function computeVariationTooltipLabel(
  value: number,
  date: string | undefined,
  type: keyof OperationSuiviCommercialisationTotal
): string | undefined {
  if (type === "chiffreAffaire") {
    return `${formatNumberToFrenchString(convertCentimeAmountToEuroAmount(value))} € à l'import du ${date}`;
  } else if (type === "nombreLot") {
    const isMoreThanOne = value > 1;
    return `${value.toString()} ${isMoreThanOne ? "lots" : "lot"} à l'import du ${date}`;
  }
  return undefined;
}
