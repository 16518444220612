import accordCommercialApi from "@/api/accordCommercialApi";
import poolAccordCommercialApi from "@/api/poolAccordCommercialApi";
import { AccordCommercialDocument } from "@domain/dto/accordCommercialDocument";
import { AccordCommercialSignataireUpdateRequest } from "@domain/dto/accordCommercialSignataireUpdateRequest";
import { AccordCommercialUpdateRequest } from "@domain/dto/accordCommercialUpdateRequest";
import { AccordCommercialValidationRequest } from "@domain/dto/accordCommercialValidationRequest";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { PoolAccordCommercialCreate } from "@domain/dto/poolAccordCommercialCreate";
import { PoolAccordCommercialHeavy } from "@domain/dto/poolAccordCommercialHeavy";
import { AccordCommercialStep } from "@domain/enum/accordCommercialStep";
import { AccordCommercialType } from "@domain/enum/accordCommercialType";
import { AccordCommercialValidationType } from "@domain/enum/accordCommercialValidationType";
import { PoolAccordCommercialStatus } from "@domain/enum/poolAccordCommercialStatus";
import { SignMethod } from "@domain/enum/signMethod";
import { ValidationStatus } from "@domain/enum/validationStatus";
import { PoolAccordCommercial } from "@/domain/poolAccordCommercial";
import { createCspDocument } from "@/utils/documentUtils";
import {
  downloadClickLink,
  setupDocumentPreview,
} from "@/utils/downloadFileUtils";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { useOperationStore } from "./operationModule.pinia";
import { PoolAccordCommercialBulkCreate } from "@domain/dto/poolAccordCommercialBulkCreate";
import { PoolAccordCommercialDocumentStatus } from "@domain/enum/poolAccordCommercialDocumentStatus";
import { GedType } from "@domain/enum/gedType";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { SignataireType } from "@domain/enum/signataireType";
import { WorkflowRollbackType } from "@/domain/enum/workflowRollbackType";
import { formatDocusignConfigurationErrorList } from "@/utils/signatureUtils";
import { isDocusignConfigurationErrorList } from "@domain/utils/docusignUtils";

type PoolAccordCommercialState = {
  poolAccordCommercialList: PoolAccordCommercialHeavy[];
  creatingAccordCommercialForPool?: string;
  accordCommercial: PoolAccordCommercialHeavy;
  accordCommercialProjectDocument: AccordCommercialDocument;
  accordCommercialActeDocument: AccordCommercialDocument;
  members: AnnuaireUser[];
  signedActeDocument: AccordCommercialDocument;
  certificateDocument: AccordCommercialDocument;
};
function createAccordCommercial(): PoolAccordCommercialHeavy {
  return {
    id: "",
    idOperation: "",
    name: "",
    type: AccordCommercialType.OFFRE_PARTICIPATION,
    step: AccordCommercialStep.CO_CONSTRUCTION,
    status: PoolAccordCommercialStatus.CO_CONSTRUCTION,
    pourcentage: 0,
    idParticipant: "",
    idChefDeFile: "",
    statusBanque: ValidationStatus.UNCHECKED,
    statusParticipant: ValidationStatus.UNCHECKED,
    signMethod: SignMethod.UNCHECKED,
    idEnvelope: undefined,
    isSignatureOrdered: false,
    signataireChefDeFileCount: 0,
    signataireParticipantCount: 0,
    validationType: AccordCommercialValidationType.SIGNATURE,
    signataireList: [],
    validatedByList: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  };
}
function createAccordCommercialDocument(): AccordCommercialDocument {
  return {
    id: "",
    name: "",
    size: 0,
    version: 1,
    mimeType: "",
    idDocument: "",
    idUploader: "",
    documentStatus: PoolAccordCommercialDocumentStatus.PROJET,
    lastNameUploader: "",
    firstNameUploader: "",
    idPoolAccordCommercial: "",
    createdAt: new Date(),
    gedType: GedType.POOL_ACCORD_COMMERCIAL,
  };
}

export const usePoolAccordCommercialStore = defineStore(
  ModuleType.PoolAccordCommercial,
  {
    state: (): PoolAccordCommercialState => ({
      poolAccordCommercialList: [],
      creatingAccordCommercialForPool: undefined,
      accordCommercial: createAccordCommercial(),
      accordCommercialProjectDocument: createCspDocument(),
      accordCommercialActeDocument: createCspDocument(),
      members: [],
      certificateDocument: createAccordCommercialDocument(),
      signedActeDocument: createAccordCommercialDocument(),
    }),
    getters: {
      getPoolAccordCommercialList(state): PoolAccordCommercialHeavy[] {
        return state.poolAccordCommercialList;
      },
      getAccordCommercial(state): PoolAccordCommercialHeavy {
        return state.accordCommercial;
      },
      getAccordCommercialProjectDocument(state): AccordCommercialDocument {
        return state.accordCommercialProjectDocument;
      },
      getAccordCommercialActeDocument(state): AccordCommercialDocument {
        return state.accordCommercialActeDocument;
      },
      getAccordCommercialSignedActeDocument(state): AccordCommercialDocument {
        return state.signedActeDocument;
      },
      getAccordCommercialCertificateDocument(state): AccordCommercialDocument {
        return state.certificateDocument;
      },
      getMembers(state): AnnuaireUser[] {
        return state.members;
      },
      hasAccordCommercialProjectDocument(state): boolean {
        return state.accordCommercialProjectDocument.id !== "";
      },
      hasAccordCommercialActeDocument(): boolean {
        return this.getAccordCommercialActeDocument.id !== "";
      },
      getSignatureConfigurationReady(state): boolean {
        return (
          state.accordCommercial.signataireList?.length ===
          state.accordCommercial.signataireChefDeFileCount +
            state.accordCommercial.signataireParticipantCount
        );
      },
    },
    actions: {
      SetCreatingAccordCommercialForPool(idParticipant: string) {
        this.creatingAccordCommercialForPool = idParticipant;
      },
      SetAccordCommercial(accordCommercial: PoolAccordCommercialHeavy): void {
        this.accordCommercial = accordCommercial;
      },
      SetAccordCommercialProjectDocument(
        document: AccordCommercialDocument
      ): void {
        this.accordCommercialProjectDocument = document;
      },
      ResetAccordCommercialProjectDocument(): void {
        this.accordCommercialProjectDocument = createCspDocument();
      },
      SetAccordCommercialActeDocument(
        document: AccordCommercialDocument
      ): void {
        this.accordCommercialActeDocument = document;
      },
      SetMembers(members: AnnuaireUser[]): void {
        this.members = members;
      },
      ResetAccordCommercialActeDocument(): void {
        this.accordCommercialActeDocument = createCspDocument();
      },
      ResetAccordCommercial(): void {
        this.accordCommercial = createAccordCommercial();
      },

      SetCertificateDocument(document: AccordCommercialDocument): void {
        this.certificateDocument = document;
      },
      SetSignedActeDocument(document: AccordCommercialDocument): void {
        this.signedActeDocument = document;
      },

      ResetCertificateDocument(): void {
        this.certificateDocument = createAccordCommercialDocument();
      },
      ResetSignedActeDocument(): void {
        this.signedActeDocument = createAccordCommercialDocument();
      },

      async fetchPoolAccordCommercialListForOperation(
        idOperation?: string
      ): Promise<void> {
        if (!idOperation) {
          idOperation = useOperationStore().getCurrentOperationId;
        }

        this.poolAccordCommercialList = [];

        if (!idOperation) {
          Sentry.captureException(
            new Error(
              "fetchPoolAccordCommercialListForOperation has been called without arguments"
            )
          );
          return;
        }

        const result =
          await poolAccordCommercialApi.fetchPoolAccordCommercialisationListForOperation(
            idOperation
          );
        if (result.status === 200) {
          this.poolAccordCommercialList = result.data;
        }
      },

      async createPoolAccordCommercialForOperation(
        poolAccordCommercialCreate: PoolAccordCommercialCreate,
        idOperation: string
      ): Promise<PoolAccordCommercial | undefined> {
        const result = await poolAccordCommercialApi.create(
          idOperation,
          poolAccordCommercialCreate
        );

        if (result.status === 409) {
          toastError(
            "Le pourcentage total des parts en risque doit être inférieur ou égal à 100"
          );
          return undefined;
        }

        if (result.status === 400) {
          toastError(
            "Le pourcentage des parts en risque doit être un nombre entier"
          );
          return undefined;
        }

        return result.data;
      },

      async bulkCreate(
        poolAccordCommercialCreate: PoolAccordCommercialBulkCreate,
        idOperation: string,
        file: File | null
      ): Promise<boolean> {
        const result = await poolAccordCommercialApi.bulkCreate(
          idOperation,
          poolAccordCommercialCreate
        );

        if (result.status === 409) {
          toastError(
            "Le pourcentage total des parts en risque doit être inférieur ou égal à 100"
          );
          return false;
        }

        if (result.status === 400) {
          toastError(
            "Le pourcentage des parts en risque doit être un nombre entier"
          );
          return false;
        }

        if (file) {
          await poolAccordCommercialApi.bulkUploadProject(
            idOperation,
            result.data.map((accord) => accord.id),
            file
          );
        }

        this.poolAccordCommercialList.push(...result.data);
        toastSuccess("Les accords commerciaux ont été créés avec succès");

        return true;
      },

      async fetchAccordCommercial(
        idOperation: string,
        idPoolAccordCommercial: string
      ): Promise<void> {
        const csp = await accordCommercialApi.fetchAccordCommercial(
          idOperation,
          idPoolAccordCommercial
        );
        this.SetAccordCommercial(csp.data);
      },

      async fetchAccordCommercialProjectDocument(
        idOperation: string,
        idPoolAccordCommercial: string
      ): Promise<void> {
        const document =
          await accordCommercialApi.fetchAccordCommercialProjectDocument(
            idOperation,
            idPoolAccordCommercial
          );
        if (document.status === 200) {
          this.SetAccordCommercialProjectDocument(document.data);
        } else {
          this.ResetAccordCommercialProjectDocument();
        }
      },

      async fetchAccordCommercialActeDocument(
        idOperation: string,
        idPoolAccordCommercial: string
      ): Promise<void> {
        const document =
          await accordCommercialApi.fetchAccordCommercialActeDocument(
            idOperation,
            idPoolAccordCommercial
          );
        if (document.status === 200) {
          this.SetAccordCommercialActeDocument(document.data);
        } else {
          this.ResetAccordCommercialActeDocument();
        }
      },

      async fetchMembers(data: {
        idOperation: string;
        idPoolAccordCommercial: string;
      }): Promise<void> {
        await accordCommercialApi.fetchMembers(data).then((response) => {
          this.SetMembers(response.data);
        });
      },

      async downloadDocument(data: {
        idOperation: string;
        idPoolAccordCommercial: string;
        idDocument: string;
        documentName: string;
        documentMimeType: string;
      }): Promise<void> {
        await accordCommercialApi
          .fetchAccordCommercialDocumentFile(data)
          .then((response) => {
            const documentStream: BlobPart = response.data;
            downloadClickLink(documentStream, {
              name: data.documentName,
              type: data.documentMimeType,
            });
          })
          .catch(() => {
            toastError("Erreur lors du téléchargement du document.");
          });
      },

      async previewAccordCommercialDocument(data: {
        idOperation: string;
        idPoolAccordCommercial: string;
        idDocument: string;
      }): Promise<void> {
        const response =
          await accordCommercialApi.fetchAccordCommercialDocumentFile(data);

        setupDocumentPreview(response.data);
      },

      async uploadAccordCommercialProjectDocument(data: {
        idOperation: string;
        idPoolAccordCommercial: string;
        file: File;
      }): Promise<PendingDocument | undefined> {
        const result = await accordCommercialApi.uploadProjectDocument(
          data.idOperation,
          data.idPoolAccordCommercial,
          data.file
        );

        return result.data.shift();
      },

      async validateAccordCommercial(data: {
        idOperation: string;
        idPoolAccordCommercial: string;
        updateRequest: AccordCommercialValidationRequest;
      }): Promise<void> {
        const response = await accordCommercialApi.validate(data).catch(() => {
          toastError("Erreur lors de la validation du document");
        });
        if (response?.status === 200) {
          if (
            [
              data.updateRequest.statusParticipant,
              data.updateRequest.statusBanque,
            ].includes(ValidationStatus.DECLINED)
          ) {
            toastSuccess("Le document a bien été refusé");
          } else if (
            [
              data.updateRequest.statusParticipant,
              data.updateRequest.statusBanque,
            ].includes(ValidationStatus.UNCHECKED)
          ) {
            toastSuccess("La validation a bien été annulée");
          } else {
            toastSuccess(
              `Le document a bien été validé ${data.updateRequest.statusParticipant === ValidationStatus.ACCEPTED_WITH_CONDITION ? "sous conditions" : ""}`
            );
          }
          this.SetAccordCommercial(response.data);
        }
      },

      async chooseSignMethod(signMethod: SignMethod): Promise<void> {
        const idOperation = useOperationStore().getOperation.id;
        const idPoolAccordCommercial = this.getAccordCommercial.id;
        const updateRequest: AccordCommercialUpdateRequest = {
          signMethod,
        };
        return accordCommercialApi
          .chooseSignMethod({
            idOperation,
            idPoolAccordCommercial,
            updateRequest,
          })
          .then((response) => {
            toastSuccess("Votre choix de signature a bien été enregistré");
            this.SetAccordCommercial(response.data);
          })
          .catch(() => {
            toastError("Votre choix de signature n'a pas pu être enregistré");
          });
      },

      async manuallySign(): Promise<void> {
        const idOperation = useOperationStore().getOperation.id;
        const idPoolAccordCommercial = this.getAccordCommercial.id;
        return accordCommercialApi
          .manuallySign({
            idOperation,
            idPoolAccordCommercial,
          })
          .then((response) => {
            toastSuccess("Votre signature a bien été enregistrée");
            this.SetAccordCommercial(response.data);
          })
          .catch(() => {
            toastError("Votre signature n'a pas pu être enregistrée");
          });
      },

      async updateSignataireChefDeFileCountConfiguration(data: {
        idOperation: string;
        accordCommercial: PoolAccordCommercialHeavy;
        signataireCount: number;
      }): Promise<void> {
        const updateRequest: AccordCommercialSignataireUpdateRequest = {
          signataireChefDeFileCount: data.signataireCount,
          signataireParticipantCount:
            data.accordCommercial.signataireParticipantCount,
          signataireCreateList: this.accordCommercial.signataireList.map(
            (signataire) => ({
              idSignataire: signataire.user.id,
              idRecipient: signataire.idRecipient,
              type: SignataireType.INTERNE,
            })
          ),
        };
        return accordCommercialApi
          .updateSignataireConfiguration({
            idOperation: data.idOperation,
            idPoolAccordCommercial: data.accordCommercial.id,
            updateRequest,
          })
          .then((response) => {
            if (response.status === 201) {
              this.SetAccordCommercial(response.data);
            } else if (response.status === 409) {
              toastError(
                "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte !"
              );
            }
          })
          .catch(() => {
            toastError("Erreur lors de l'ajout du signataire");
          });
      },

      async updateSignataireParticipantCountConfiguration(data: {
        idOperation: string;
        accordCommercial: PoolAccordCommercialHeavy;
        signataireCount: number;
      }): Promise<void> {
        const updateRequest: AccordCommercialSignataireUpdateRequest = {
          signataireChefDeFileCount:
            data.accordCommercial.signataireChefDeFileCount,
          signataireParticipantCount: data.signataireCount,
          signataireCreateList: this.accordCommercial.signataireList.map(
            (signataire) => ({
              idSignataire: signataire.user.id,
              idRecipient: signataire.idRecipient,
              type: SignataireType.INTERNE,
            })
          ),
        };
        return accordCommercialApi
          .updateSignataireConfiguration({
            idOperation: data.idOperation,
            idPoolAccordCommercial: data.accordCommercial.id,
            updateRequest,
          })
          .then((response) => {
            if (response.status === 201) {
              this.SetAccordCommercial(response.data);
            } else if (response.status === 409) {
              toastError(
                "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte !"
              );
            }
          })
          .catch(() => {
            toastError("Erreur lors de l'ajout du signataire");
          });
      },

      async updateSignatureNewSignataireConfiguration(
        accordCommercialSignataireUpdateRequest: AccordCommercialSignataireUpdateRequest
      ): Promise<void> {
        const idOperation = useOperationStore().getOperation.id;
        const accordCommercial = this.getAccordCommercial;

        return accordCommercialApi
          .updateSignataireConfiguration({
            idOperation,
            idPoolAccordCommercial: accordCommercial.id,
            updateRequest: accordCommercialSignataireUpdateRequest,
          })
          .then((result) => {
            if (result.status === 201) {
              this.SetAccordCommercial(result.data);
              toastSuccess("Signataire ajouté avec succès");
            } else if (result.status === 409) {
              toastError(
                "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte !"
              );
            }
          })
          .catch(() => {
            toastError("Erreur lors de l'ajout du signataire");
          });
      },

      async updateSignatureOrdered(value: boolean): Promise<void> {
        const idOperation = useOperationStore().getOperation.id;
        const idPoolAccordCommercial = this.getAccordCommercial.id;
        const updateRequest: AccordCommercialUpdateRequest = {
          isSignatureOrdered: value,
        };
        return accordCommercialApi
          .update(idOperation, idPoolAccordCommercial, updateRequest)
          .then((response) => {
            toastSuccess("Mise à jour effectuée avec succès");
            this.SetAccordCommercial(response.data);
          })
          .catch(() => {
            toastError("Votre choix de signature n'a pas pu être enregistré");
          });
      },

      async initializeSignature() {
        try {
          const response = await accordCommercialApi.initializeSignature(
            this.accordCommercial.idOperation,
            this.accordCommercial.id
          );

          if (response.status === 200) {
            this.SetAccordCommercial(response.data);
            return toastSuccess("Enveloppe créée avec succès");
          }

          if (response.status === 409) {
            return toastWarn(
              "Le document à signer n'est pas au format A4 portrait. Veuillez utiliser la signature libre pour positionner les tampons",
              15 * 1000
            );
          }

          if (
            response.status === 400 &&
            isDocusignConfigurationErrorList(response.data)
          ) {
            return toastError(
              formatDocusignConfigurationErrorList(response.data)
            );
          }

          toastError("Erreur lors de la création de l'enveloppe");
        } catch (e) {
          toastError("Erreur lors de la création de l'enveloppe");
        }
      },

      async startSignatureCeremonie(
        accordCommercial: PoolAccordCommercialHeavy
      ): Promise<void> {
        const idOperation = accordCommercial.idOperation;
        const idPoolAccordCommercial = accordCommercial.id;

        return poolAccordCommercialApi
          .startSignatureCeremonie(idOperation, idPoolAccordCommercial)
          .then((response) => {
            if (response.status === 200) {
              const url = response.data;
              window.location = url as unknown as Location;
            } else {
              toastError("Erreur lors de la création de la vue");
            }
          })
          .catch(() => {
            toastError("Erreur lors de la création de la vue");
          });
      },

      async fetchAccordCommercialSignedActeDocument(
        idOperation: string,
        idPoolAccordCommercial: string
      ): Promise<void> {
        this.ResetSignedActeDocument();

        const result = await poolAccordCommercialApi.fetchSignedActe(
          idOperation,
          idPoolAccordCommercial
        );

        if (result.data) {
          this.SetSignedActeDocument(result.data);
        }
      },

      async fetchAccordCommercialCertificateDocument(
        idOperation: string,
        idPoolAccordCommercial: string
      ): Promise<void> {
        this.ResetCertificateDocument();

        const result = await poolAccordCommercialApi.fetchCertificate(
          idOperation,
          idPoolAccordCommercial
        );

        if (result.data) {
          this.SetCertificateDocument(result.data);
        }
      },

      async remindSignatureToRecipient(
        idOperation: string,
        idAccordCommercial: string,
        idRecipient: number
      ): Promise<void> {
        const response =
          await poolAccordCommercialApi.remindSignatureToRecipient(
            idOperation,
            idAccordCommercial,
            idRecipient
          );
        if (response.status != 201) {
          toastError("Erreur lors de la relance");
          return;
        }
        toastSuccess("Le mail de relance a bien été renvoyé");
      },

      async rollbackAccordCommercialWorkflow(
        rollbackType: WorkflowRollbackType
      ): Promise<void> {
        const idOperation = this.getAccordCommercial.idOperation;
        const idAccordCommercial = this.getAccordCommercial.id;
        await poolAccordCommercialApi.rollbackWorkflow(
          idOperation,
          idAccordCommercial,
          rollbackType
        );
      },
    },
  }
);
