import { SuiviBudgetOperationImport } from "@domain/dto/suiviBudgetOperationImport";
import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import suiviBudgetApi from "@/api/suiviBudgetApi";
import { SuiviBudgetDocumentUpload } from "@/domain/suiviBudgetDocumentUpload";
import { buildSuiviBudgetImportCreation } from "@/utils/suiviBudgetImportPromoteurUtils";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { SuiviBudgetResponse } from "@domain/dto/suiviBudgetResponse";
import { SuiviBudgetStep } from "@domain/enum/suiviBudgetStep";
import { useDocumentStore } from "@/store/documentModule.pinia";
import { useOperationStore } from "@/store/operationModule.pinia";
import { SuiviBudgetImport } from "@domain/dto/suiviBudgetImport";
import { getMostRecent } from "@/utils/dateUtils";
import { SuiviBudgetConfigurationCreationRequest } from "@domain/dto/suiviBudgetConfigurationCreationRequest";
import { SuiviBudgetConfiguration } from "@domain/dto/suiviBudgetConfiguration";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { toastSuccess, toastWarn, toastError } from "@/utils/toastUtils";

export enum SuiviBudgetVue {
  GLOBAL = "GLOBAL",
  TRANCHE = "TRANCHE",
}

type SuiviBudgetStateType = {
  suiviBudgetImportDocumentContent: string[][];
  suiviBudgetResponse: SuiviBudgetResponse;
  suiviBudgetConfiguration: SuiviBudgetConfiguration;
  vue: SuiviBudgetVue;
};

export const useSuiviBudget = defineStore(ModuleType.SuiviBudget, {
  state: (): SuiviBudgetStateType => ({
    suiviBudgetImportDocumentContent: [],
    suiviBudgetResponse: {
      step: SuiviBudgetStep.INITIALIZATION,
      operationImportList: [],
    },
    suiviBudgetConfiguration: {
      idOperation: "",
      idBanque: "",
      montantHt: -1,
      montantTtc: -1,
      nomPoste: -1,
      premiereLigne: -1,
    },
    vue: SuiviBudgetVue.GLOBAL,
  }),

  getters: {
    getSuiviBudgetList(state): SuiviBudgetOperationImport[] {
      return state.suiviBudgetResponse.operationImportList;
    },
    getSuiviBudgetResponse(state): SuiviBudgetResponse {
      return state.suiviBudgetResponse;
    },
    getSuiviBudgetStep(state): SuiviBudgetStep {
      return state.suiviBudgetResponse.step;
    },
    getSuiviBudgetImportList(state): SuiviBudgetImport[] {
      return state.suiviBudgetResponse.importList || [];
    },
    getSuiviBudgetImportDocumentContent(state): string[][] {
      return state.suiviBudgetImportDocumentContent;
    },
    getSuiviBudgetConfiguration(
      state
    ): SuiviBudgetConfigurationCreationRequest {
      return state.suiviBudgetConfiguration;
    },
    getSuiviBudgetVue(state): SuiviBudgetVue {
      return state.vue;
    },
  },
  actions: {
    SetSuiviBudgetVue(vue: SuiviBudgetVue): void {
      this.vue = vue;
    },
    SetSuiviBudgetImportDocumentContent(
      suiviBudgetImportDocumentContent: string[][]
    ): void {
      this.suiviBudgetImportDocumentContent = suiviBudgetImportDocumentContent;
    },
    SetSuiviBudgetConfigurationCreationRequest(
      suiviBudgetConfiguration: SuiviBudgetConfigurationCreationRequest
    ): void {
      this.suiviBudgetConfiguration = suiviBudgetConfiguration;
    },
    ResetSuiviBudgetImportDocumentContent(): void {
      this.suiviBudgetImportDocumentContent = [];
    },
    ResetSuiviBudgetConfigurationCreationRequest(): void {
      this.suiviBudgetConfiguration = {
        idOperation: "",
        idBanque: "",
        montantHt: 0,
        montantTtc: 0,
        nomPoste: 0,
        premiereLigne: 0,
      };
    },

    async fetchSuiviBudgetList(idOperation: string): Promise<void> {
      const result = await suiviBudgetApi.fetchAllForOperation(idOperation);
      if (result.data) {
        this.suiviBudgetResponse = result.data;
      }
    },

    async uploadSuiviBudgetDocumentList(
      idOperation: string,
      idBanque: string,
      documents: SuiviBudgetDocumentUpload[]
    ): Promise<void> {
      try {
        const importCreation = buildSuiviBudgetImportCreation(
          idOperation,
          idBanque,
          documents
        );
        await suiviBudgetApi.uploadSuiviBudgetPromoteur(importCreation);
        await this.fetchSuiviBudgetList(idOperation);
      } catch (error) {
        toastError("Erreur lors de l'ajout de document(s)");
      }
    },

    async downloadSuiviBudget(idOperation: string): Promise<void> {
      await suiviBudgetApi
        .downloadSuiviBudget(idOperation)
        .then((response) => {
          const buffer = Buffer.from(response.data as string, "base64");
          downloadClickLink(buffer, {
            name: "export_suivi_budget.xlsx",
            type: NemmoMimeType.EXCEL,
          });
        })
        .catch(() => {
          toastError("Erreur lors du téléchargement du document.");
        });
    },

    async fetchLatestSuiviBudgetImportDocumentContent(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const mostRecentImport = getMostRecent(this.getSuiviBudgetImportList);

      if (!mostRecentImport) {
        return;
      }

      const response =
        await suiviBudgetApi.fetchSuiviBudgetImportDocumentContent(
          idOperation,
          mostRecentImport.id
        );

      if (response.data && response.status === 200) {
        this.SetSuiviBudgetImportDocumentContent(response.data);
      }
    },
    async createSuiviBudgetConfiguration(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const banque = useUserProfileStore().getUserBanqueGroupId;

      if (!banque) {
        toastError(`Seul un utilisateur banque peut effectuer cette requête`);
      } else {
        const budgetConfiguration = {
          ...this.getSuiviBudgetConfiguration,
          idOperation,
          idBanque: banque.id,
        };
        const result =
          await suiviBudgetApi.createSuiviBudgetConfiguration(
            budgetConfiguration
          );
        if (result.status === 201) {
          toastSuccess(`Configuration sauvegardée`);
        }
      }
    },

    async downloadSuiviBudgetDocumentsPromoteur(
      idOperation: string
    ): Promise<void> {
      try {
        const response =
          await suiviBudgetApi.downloadSuiviBudgetDocumentsPromoteur(
            idOperation
          );

        if (response.status === 200) {
          const idZip = response.data;
          const documentStore = useDocumentStore();
          const operation = useOperationStore().getOperation;
          documentStore.documentDownloadZipSubscribed[idZip] = operation.name;
        } else if (response.status === 409) {
          toastWarn("Aucun document de suivi de budget");
        }
      } catch (error) {}
    },
  },
});
