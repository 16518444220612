import {
  ErrorCreationTableauSuiviBudget,
  ErrorCreationTableauSuiviBudgetType,
  SuiviBudgetBilanLigneCategory,
  SuiviBudgetBilanLigneType,
  SuiviBudgetCategoryPourcentage,
  SuiviBudgetLigneCategory,
  SuiviBudgetLigneCategoryCellData,
  SuiviBudgetLigneCategoryVariation,
  SuiviBudgetSousLigne,
  SuiviBudgetSousLigneCellData,
} from "@/domain/suiviBudget";
import {
  SuiviBudgetOperationImport,
  SuiviBudgetOperationImportCategory,
  SuiviBudgetOperationImportLigne,
} from "@domain/dto/suiviBudgetOperationImport";
import { SuiviBudgetLigneType } from "@domain/enum/suiviBudgetLigneType";
import { Err, Ok, Result } from "@hqoss/monads";
import { sortAscending, sortDescending } from "./sortUtils";
import { formatDate } from "./dateUtils";
import { computeVariationPourcentageValues, sum } from "./numberUtils";
import { groupBy } from "lodash";
import { SuiviBudgetPoste } from "@domain/enum/suiviBudgetPoste";
import { SuiviVariation, VariationType } from "@/domain/suiviVariation";

const SuiviBudgetPosteLabels: { [key in SuiviBudgetPoste]: string } = {
  [SuiviBudgetPoste.TERRAIN_NUMERAIRE]: "Terrain (partie en numéraire)",
  [SuiviBudgetPoste.TERRAIN_DATION]: "Terrain (partie en dation)",
  [SuiviBudgetPoste.FRAIS_ACQUISITION]: "Frais d'acquisition",
  [SuiviBudgetPoste.INDEMNITES]: "Indemnités",
  [SuiviBudgetPoste.INTERMEDIAIRE_FONCIER]: "Intermédiaire foncier",
  [SuiviBudgetPoste.TAXES_PARTICIPATIONS]: "Taxes et participations",
  [SuiviBudgetPoste.AUTRES_CHARGES_FONCIER]: "Autres charges (foncier)",
  [SuiviBudgetPoste.DEMOLITION_DESAMIANTAGE_DEPOLLUTION]:
    "Démolition / Désamiantage / Dépollution",
  [SuiviBudgetPoste.BRANCHEMENTS_TRAVAUX_PREPARATOIRES]:
    "Branchements et travaux préparatoires",
  [SuiviBudgetPoste.TRAVAUX_VRD_ESPACES_VERTS]: "Travaux VRD & espaces verts",
  [SuiviBudgetPoste.ALEAS_TRAVAUX_SUPPLEMENTAIRES_VRD_ESPACES_VERTS]:
    "Aléas et travaux supplémentaires VRD & espaces verts",
  [SuiviBudgetPoste.TRAVAUX_BATIMENT]: "Travaux bâtiment",
  [SuiviBudgetPoste.ALEAS_TRAVAUX_SUPPLEMENTAIRES]:
    "Aléas et travaux supplémentaires",
  [SuiviBudgetPoste.TRAVAUX_MODIFICATIFS]: "Travaux modificatifs",
  [SuiviBudgetPoste.TRAVAUX_ANNEXES]: "Travaux annexes",
  [SuiviBudgetPoste.HONORAIRES_TECHNIQUES_DIVERS]:
    "Honoraires techniques divers",
  [SuiviBudgetPoste.GEOMETRE]: "Géomètre",
  [SuiviBudgetPoste.ARCHITECTE_PRESTATION_CONCEPTION]:
    "Architecte & prestation conception",
  [SuiviBudgetPoste.MAITRISE_OEUVRE_PRESTATIONS_EXECUTION_EXTERNE]:
    "Maîtrise d'œuvre & prestations exécution externe",
  [SuiviBudgetPoste.BUREAUX_ETUDES]: "Bureaux d'études",
  [SuiviBudgetPoste.CONTROLE_TECHNIQUE_SECURITE]:
    "Contrôle technique & sécurité",
  [SuiviBudgetPoste.ASSURANCES]: "Assurances",
  [SuiviBudgetPoste.MAITRISE_OEUVRE_INTERNE]: "Maîtrise d'œuvre interne",
  [SuiviBudgetPoste.CHARGES_DIVERSES]: "Charges diverses",
  [SuiviBudgetPoste.CHARGES_ADMINISTRATION]: "Charges d'administration",
  [SuiviBudgetPoste.SAV_CONTENTIEUX]: "SAV et contentieux",
  [SuiviBudgetPoste.PUBLICITE]: "Publicité",
  [SuiviBudgetPoste.AIDES_VENTE]: "Aides à la vente",
  [SuiviBudgetPoste.HONORAIRES_EXTERNES_COMMERCIALISATION]:
    "Honoraires externes de commercialisation",
  [SuiviBudgetPoste.HONORAIRES_INTERNES_COMMERCIALISATION]:
    "Honoraires internes de commercialisation",
  [SuiviBudgetPoste.HONORAIRES_GESTION_EXTERNE]:
    "Honoraires de gestion externes",
  [SuiviBudgetPoste.HONORAIRES_GESTION_INTERNE]:
    "Honoraires de gestion interne",
  [SuiviBudgetPoste.GARANTIE_FINANCIERE_ACHEVEMENT]:
    "Garantie financière d'achèvement",
  [SuiviBudgetPoste.CAUTIONS_GARANTIES]: "Cautions et garanties",
  [SuiviBudgetPoste.FRAIS_FINANCIERS_VENDEUR_ACQUEREUR]:
    "Frais financiers vendeur/acquéreur",
  [SuiviBudgetPoste.FRAIS_TENUE_COMPTE]: "Frais de tenue de compte",
  [SuiviBudgetPoste.FRAIS_FINANCIERS_EXTERNES]: "Frais financiers externes",
  [SuiviBudgetPoste.FRAIS_FINANCIERS_INTERNES]: "Frais financiers internes",
  [SuiviBudgetPoste.VENTE_DETAIL]: "Vente au détail",
  [SuiviBudgetPoste.VENTE_BLOC]: "Vente en bloc",
  [SuiviBudgetPoste.DATION]: "Dation",
  [SuiviBudgetPoste.PERSONNALISATION]: "Personnalisation",
  [SuiviBudgetPoste.REFACTURATIONS]: "Refacturations",
};

export function getSuiviBudgetPosteLabel(poste: SuiviBudgetPoste): string {
  return SuiviBudgetPosteLabels[poste];
}

export function convertSuiviBudgetLigneTypeToLabel(
  type: SuiviBudgetLigneType
): string {
  switch (type) {
    case SuiviBudgetLigneType.TERRAIN:
      return "Terrain";
    case SuiviBudgetLigneType.AMENAGEMENT:
      return "Aménagement";
    case SuiviBudgetLigneType.TRAVAUX:
      return "Travaux";
    case SuiviBudgetLigneType.HONORAIRES_TECHNIQUES:
      return "Honoraires techniques";
    case SuiviBudgetLigneType.FRAIS_ANNEXES_CONSTRUCTION:
      return "Frais annexes contruction";
    case SuiviBudgetLigneType.FRAIS_COMMERCIALISATION:
      return "Frais commercialisation";
    case SuiviBudgetLigneType.GESTION:
      return "Gestion";
    case SuiviBudgetLigneType.FRAIS_FINANCIERS:
      return "Frais financiers";
    case SuiviBudgetLigneType.RECETTES:
      return "Recettes";
    default:
      return "";
  }
}

export function convertSuiviBudgetBilanLigneTypeToLabel(
  type: SuiviBudgetBilanLigneType
): string {
  switch (type) {
    case SuiviBudgetBilanLigneType.MARGE:
      return "Marge";
    case SuiviBudgetBilanLigneType.PRIX_VENTE:
      return "Prix de vente";
    case SuiviBudgetBilanLigneType.PRIX_REVIENT_TOTAL:
      return "Prix de revient total";
    default:
      return "";
  }
}

export function getColorBySuiviBudgetLigneType(
  type: SuiviBudgetLigneType
): string {
  switch (type) {
    case SuiviBudgetLigneType.TERRAIN:
      return "dark-purple";
    case SuiviBudgetLigneType.AMENAGEMENT:
      return "purple";
    case SuiviBudgetLigneType.TRAVAUX:
      return "blue";
    case SuiviBudgetLigneType.HONORAIRES_TECHNIQUES:
      return "light-blue";
    case SuiviBudgetLigneType.FRAIS_ANNEXES_CONSTRUCTION:
      return "dark-green";
    case SuiviBudgetLigneType.FRAIS_COMMERCIALISATION:
      return "green";
    case SuiviBudgetLigneType.GESTION:
      return "yellow";
    case SuiviBudgetLigneType.FRAIS_FINANCIERS:
      return "dark-yellow";
    case SuiviBudgetLigneType.RECETTES:
      return "";
    default:
      return "";
  }
}

export function convertToSuiviBudgetBilanTableau(
  importList: SuiviBudgetOperationImport[]
): SuiviBudgetBilanLigneCategory[] {
  const sortedImportList = sortSuiviBudgetImportList(importList);
  const bilanPrixRevientTotal: SuiviBudgetBilanLigneCategory = {
    type: SuiviBudgetBilanLigneType.PRIX_REVIENT_TOTAL,
    cellList: sortedImportList.map(
      (importVersion) => importVersion.prixRevientCentime
    ),
  };
  const bilanPrixVente: SuiviBudgetBilanLigneCategory = {
    type: SuiviBudgetBilanLigneType.PRIX_VENTE,
    cellList: sortedImportList.map(
      (importVersion) => importVersion.prixVenteCentime
    ),
  };
  const bilanMarge: SuiviBudgetBilanLigneCategory = {
    type: SuiviBudgetBilanLigneType.MARGE,
    cellList: sortedImportList.map(
      (importVersion) => importVersion.margeAbsolueCentime
    ),
  };

  let bilanLigneCategoryList = [
    bilanPrixRevientTotal,
    bilanPrixVente,
    bilanMarge,
  ];
  if (sortedImportList.length >= 2) {
    bilanLigneCategoryList = addVariationToBilanLigneCategory(
      bilanLigneCategoryList
    );
  }

  return bilanLigneCategoryList;
}

export function convertToSuiviBudgetTableau(
  importList: SuiviBudgetOperationImport[]
): Result<SuiviBudgetLigneCategory[], ErrorCreationTableauSuiviBudget> {
  const sortedImportList = sortSuiviBudgetImportList(importList);
  if (sortedImportList.length === 0) {
    const erreur: ErrorCreationTableauSuiviBudget = {
      errorType: ErrorCreationTableauSuiviBudgetType.AUCUN_IMPORT,
      message: "Aucun import",
    };
    return Err(erreur);
  }
  const importOriginal = sortedImportList[0];

  const createdLigneCategoryList: SuiviBudgetLigneCategory[] = [];
  for (const category of importOriginal.categoryList) {
    const ligneCategory: SuiviBudgetLigneCategory = {
      type: category.type,
      categoryCellList: [buildLigneCategoryCellData(category)],
      sousLigneList: initSousLigneList(category),
    };
    createdLigneCategoryList.push(ligneCategory);
  }

  if (sortedImportList.length === 1) {
    const sortedLigneCategoryList = sortSuiviBudgetCategoryList(
      createdLigneCategoryList
    );
    return Ok(sortedLigneCategoryList);
  }

  const importVersionList = sortedImportList.slice(1);
  for (const importVersion of importVersionList) {
    for (const createdLigneCategory of createdLigneCategoryList) {
      const foundCategory = importVersion.categoryList.find(
        (category) => category.type === createdLigneCategory.type
      );

      if (!foundCategory) {
        const erreur: ErrorCreationTableauSuiviBudget = {
          errorType: ErrorCreationTableauSuiviBudgetType.CATEGORIE_NON_TROUVEE,
          message: `Catégorie ${createdLigneCategory.type} non trouvée`,
        };
        return Err(erreur);
      }
      createdLigneCategory.categoryCellList.push(
        buildLigneCategoryCellData(foundCategory)
      );

      for (const createdSousLigne of createdLigneCategory.sousLigneList) {
        const foundSousLigne = foundCategory.ligneList.find(
          (ligne) => ligne.posteType === createdSousLigne.posteType
        );
        if (!foundSousLigne) {
          const erreur: ErrorCreationTableauSuiviBudget = {
            errorType:
              ErrorCreationTableauSuiviBudgetType.SOUS_CATEGORIE_NON_TROUVEE,
            message: `Sous-catégorie ${createdSousLigne.posteType} non trouvée`,
          };
          return Err(erreur);
        }
        createdSousLigne.sousLigneCellList.push(
          buildSousLigneCellData(foundSousLigne)
        );
      }
    }
  }

  const createdLigneCategoryWithVariationList =
    addVariationPourcentageToLigneCategory(createdLigneCategoryList);

  const sortedLigneCategoryList = sortSuiviBudgetCategoryList(
    createdLigneCategoryWithVariationList
  );

  return Ok(sortedLigneCategoryList);
}

function sortSuiviBudgetImportList(
  importList: SuiviBudgetOperationImport[]
): SuiviBudgetOperationImport[] {
  const result: SuiviBudgetOperationImport[] = [];
  const sortedImportList = [...importList].sort((i1, i2) => {
    return sortAscending(i1.createdAt, i2.createdAt);
  });
  if (sortedImportList.length === 0) {
    return result;
  }

  if (sortedImportList.length > 0) {
    result.push(sortedImportList[0]);
  }
  const otherImportList = [...sortedImportList.splice(1)].sort((i1, i2) => {
    return sortDescending(i1.createdAt, i2.createdAt);
  });

  return result.concat(otherImportList);
}

function sortSuiviBudgetCategoryList(
  categorieList: SuiviBudgetLigneCategory[]
): SuiviBudgetLigneCategory[] {
  const order = [
    SuiviBudgetLigneType.RECETTES,
    SuiviBudgetLigneType.TERRAIN,
    SuiviBudgetLigneType.AMENAGEMENT,
    SuiviBudgetLigneType.TRAVAUX,
    SuiviBudgetLigneType.HONORAIRES_TECHNIQUES,
    SuiviBudgetLigneType.FRAIS_ANNEXES_CONSTRUCTION,
    SuiviBudgetLigneType.FRAIS_COMMERCIALISATION,
    SuiviBudgetLigneType.GESTION,
    SuiviBudgetLigneType.FRAIS_FINANCIERS,
  ];
  const categoryOrder = new Map<SuiviBudgetLigneType, number>();
  order.forEach((o, index) => {
    categoryOrder.set(o, index);
  });

  return [...categorieList].sort((c1, c2) => {
    const ordreC1 = categoryOrder.get(c1.type) ?? 0;
    const ordreC2 = categoryOrder.get(c2.type) ?? 0;
    return sortAscending(ordreC1, ordreC2);
  });
}

function buildLigneCategoryCellData(
  category: SuiviBudgetOperationImportCategory
): SuiviBudgetLigneCategoryCellData {
  return {
    totalHTEuroCentime: category.totalHTEuroCentime,
    totalTTCEuroCentime: category.totalTTCEuroCentime,
  };
}

function initSousLigneList(
  category: SuiviBudgetOperationImportCategory
): SuiviBudgetSousLigne[] {
  return category.ligneList.map((ligne) => {
    const sousLigne: SuiviBudgetSousLigne = {
      posteType: ligne.posteType,
      type: ligne.type,
      sousLigneCellList: [buildSousLigneCellData(ligne)],
    };
    return sousLigne;
  });
}

function buildSousLigneCellData(
  ligne: SuiviBudgetOperationImportLigne
): SuiviBudgetSousLigneCellData {
  return {
    htEuroCentime: ligne.htEuroCentime,
    ttcEuroCentime: ligne.ttcEuroCentime,
  };
}

export function computeSuiviBudgetHeaderList(
  importList: SuiviBudgetOperationImport[]
): string[] {
  const sortedImportList = sortSuiviBudgetImportList(importList);

  return sortedImportList.map((importElement, index) => {
    if (index === 0) {
      return "Origine";
    }
    return formatDate(importElement.createdAt);
  });
}

export function getLatestSuiviBudgetImport(
  importList: SuiviBudgetOperationImport[]
): SuiviBudgetOperationImport | undefined {
  const sortedImportList = [...importList].sort((i1, i2) => {
    return sortAscending(i1.createdAt, i2.createdAt);
  });

  return sortedImportList.pop();
}

function filterCategoriesLegende(
  suiviBudget: SuiviBudgetOperationImport
): SuiviBudgetOperationImportCategory[] {
  return suiviBudget.categoryList.filter((categorie) =>
    [
      SuiviBudgetLigneType.TERRAIN,
      SuiviBudgetLigneType.AMENAGEMENT,
      SuiviBudgetLigneType.TRAVAUX,
      SuiviBudgetLigneType.HONORAIRES_TECHNIQUES,
      SuiviBudgetLigneType.FRAIS_ANNEXES_CONSTRUCTION,
      SuiviBudgetLigneType.FRAIS_COMMERCIALISATION,
      SuiviBudgetLigneType.GESTION,
      SuiviBudgetLigneType.FRAIS_FINANCIERS,
    ].includes(categorie.type)
  );
}

export function computeSuiviBudgetCategoryPourcentage(
  suiviBudget: SuiviBudgetOperationImport
): SuiviBudgetCategoryPourcentage[] {
  const categories = filterCategoriesLegende(suiviBudget);
  const sommeHTEuroCentime = sum(
    categories.map((category) => category.totalHTEuroCentime)
  );

  return categories.map((category) => ({
    category: category.type,
    pourcentage: (category.totalHTEuroCentime / sommeHTEuroCentime) * 100,
  }));
}

export function formatPourcentageMargeRelative(
  margeRelativePourcentage: number
): string {
  return Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(margeRelativePourcentage);
}

export function addVariationToBilanLigneCategory(
  bilanLigneList: SuiviBudgetBilanLigneCategory[]
): SuiviBudgetBilanLigneCategory[] {
  return bilanLigneList.map((bilanLigne) => {
    return {
      type: bilanLigne.type,
      cellList: bilanLigne.cellList,
      variation: computeVariationBilanLigneCategory(bilanLigne),
    };
  });
}

export function addVariationPourcentageToLigneCategory(
  ligneCategoryList: SuiviBudgetLigneCategory[]
): SuiviBudgetLigneCategory[] {
  return ligneCategoryList.map((ligneCategory) => {
    return {
      type: ligneCategory.type,
      categoryCellList: ligneCategory.categoryCellList,
      sousLigneList: addVariationPourcentageToSousLigne(
        ligneCategory.sousLigneList
      ),
      variation: computeVariationPourcentageLigneCategory(ligneCategory),
    };
  });
}

function computeVariationBilanLigneCategory(
  bilanLigneCategory: SuiviBudgetBilanLigneCategory
): SuiviVariation | undefined {
  if (
    bilanLigneCategory.cellList.length < 2 ||
    bilanLigneCategory.cellList[0] === bilanLigneCategory.cellList[1]
  ) {
    return undefined;
  }
  const original = bilanLigneCategory.cellList[0];
  const latest = bilanLigneCategory.cellList[1];

  const variationPourcentage = computeVariationPourcentageValues(
    original,
    latest
  );
  const ratio = computeVariationRatio(original, latest);
  const variationType = computeTypeVariationByBilanType(
    bilanLigneCategory.type,
    ratio
  );

  return { variationType, variationPourcentage, ratio };
}

function computeVariationPourcentageLigneCategory(
  ligneCategory: SuiviBudgetLigneCategory
): SuiviVariation | undefined {
  if (
    ligneCategory.categoryCellList.length < 2 ||
    ligneCategory.categoryCellList[0].totalHTEuroCentime ===
      ligneCategory.categoryCellList[1].totalHTEuroCentime
  ) {
    return undefined;
  }
  const original = ligneCategory.categoryCellList[0].totalHTEuroCentime;
  const latest = ligneCategory.categoryCellList[1].totalHTEuroCentime;

  const variationPourcentage = computeVariationPourcentageValues(
    original,
    latest
  );
  const ratio = computeVariationRatio(original, latest);
  const variationType = computeTypeVariationByType(ligneCategory.type, ratio);

  return { variationType, variationPourcentage, ratio };
}

export function computeVariationRatio(
  originalAmountEuroCentime: number,
  latestAmountEuroCentime: number
): number {
  let ratio = latestAmountEuroCentime / originalAmountEuroCentime - 1;
  if (
    (latestAmountEuroCentime < originalAmountEuroCentime &&
      latestAmountEuroCentime < 0) ||
    (latestAmountEuroCentime > originalAmountEuroCentime &&
      originalAmountEuroCentime < 0)
  ) {
    ratio *= -1;
  }
  return ratio;
}

function addVariationPourcentageToSousLigne(
  sousLigneList: SuiviBudgetSousLigne[]
): SuiviBudgetSousLigne[] {
  return sousLigneList.map((sousLigne) => {
    return {
      posteType: sousLigne.posteType,
      type: sousLigne.type,
      sousLigneCellList: sousLigne.sousLigneCellList,
      variation: computeVariationPourcentageSousLigne(sousLigne),
    };
  });
}

function computeVariationPourcentageSousLigne(
  sousLigne: SuiviBudgetSousLigne
): SuiviVariation | undefined {
  if (
    sousLigne.sousLigneCellList.length < 2 ||
    sousLigne.sousLigneCellList[0].htEuroCentime ===
      sousLigne.sousLigneCellList[1].htEuroCentime
  ) {
    return undefined;
  }
  const original = sousLigne.sousLigneCellList[0].htEuroCentime;
  const latest = sousLigne.sousLigneCellList[1].htEuroCentime;

  const variationPourcentage = computeVariationPourcentageValues(
    original,
    latest
  );
  const ratio = computeVariationRatio(original, latest);
  const variationType = computeTypeVariationByType(sousLigne.type, ratio);

  return { variationType, variationPourcentage, ratio };
}

export function computeMaximumVariationLigneCategory(
  ligneCategoryList: SuiviBudgetLigneCategory[]
): SuiviBudgetLigneCategoryVariation[] {
  const variationLigneList: VariationLigne[] = [];

  for (const ligneCategory of ligneCategoryList) {
    if (ligneCategory.categoryCellList.length >= 2) {
      const original = ligneCategory.categoryCellList[0].totalHTEuroCentime;
      const latest = ligneCategory.categoryCellList[1].totalHTEuroCentime;

      if (original !== latest) {
        variationLigneList.push({
          ligneCategory,
          categoryType: ligneCategory.type,
          variationAbsolue: Math.abs(latest - original),
          variationRatio: computeVariationRatio(original, latest),
          htEuroCentime: latest,
        });
      }
    }
  }

  const sortedVariationLigneList = [...variationLigneList].sort((v1, v2) => {
    return sortDescending(v1.variationAbsolue, v2.variationAbsolue);
  });

  const truncatedVariationLigneList = sortedVariationLigneList.slice(0, 2);

  return truncatedVariationLigneList.map((variationLigne) => ({
    categoryType: variationLigne.categoryType,
    variationType: computeTypeVariation(variationLigne),
    variationPourcentage: computeVariationPourcentageValues(
      variationLigne.ligneCategory.categoryCellList[0].totalHTEuroCentime,
      variationLigne.ligneCategory.categoryCellList[1].totalHTEuroCentime
    ),
    ratio: variationLigne.variationRatio,
    htEuroCentime: variationLigne.htEuroCentime,
  }));
}

interface VariationLigne {
  ligneCategory: SuiviBudgetLigneCategory;
  categoryType: SuiviBudgetLigneType;
  variationAbsolue: number;
  variationRatio: number;
  htEuroCentime: number;
}

function computeTypeVariation(variationLigne: VariationLigne): VariationType {
  return computeTypeVariationByType(
    variationLigne.categoryType,
    variationLigne.variationRatio
  );
}

function computeTypeVariationByType(
  categoryType: SuiviBudgetLigneType,
  ratio: number
): VariationType {
  const recetteTypeList = [SuiviBudgetLigneType.RECETTES];
  if (recetteTypeList.includes(categoryType)) {
    return ratio > 0 ? VariationType.GOOD : VariationType.BAD;
  } else {
    return ratio > 0 ? VariationType.BAD : VariationType.GOOD;
  }
}

function computeTypeVariationByBilanType(
  bilanType: SuiviBudgetBilanLigneType,
  ratio: number
): VariationType {
  const recetteTypeList = [
    SuiviBudgetBilanLigneType.PRIX_VENTE,
    SuiviBudgetBilanLigneType.MARGE,
  ];
  if (recetteTypeList.includes(bilanType)) {
    return ratio > 0 ? VariationType.GOOD : VariationType.BAD;
  } else {
    return ratio > 0 ? VariationType.BAD : VariationType.GOOD;
  }
}

export type TrancheImportList = {
  nomTranche: string;
  importList: SuiviBudgetOperationImport[];
};

export function splitSuiviBudgetOperationImportListByTranche(
  budgetImportList: SuiviBudgetOperationImport[]
): TrancheImportList[] {
  const importListByNomTranche = groupBy(
    budgetImportList,
    (importElement) => importElement.nomTranche
  );

  const trancheImportListList: TrancheImportList[] = [];
  for (const [nomTranche, importList] of Object.entries(
    importListByNomTranche
  )) {
    trancheImportListList.push({
      nomTranche,
      importList,
    });
  }

  return trancheImportListList;
}
